import { useState } from "react";
import moment from "moment";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { Btn, H4 } from "../../../../AbstractElements";
import { AddTaskHeading, Href, Tasks } from "../../../../utils/Constant";
import TaskList from "./TaskList";
import Calendar from "./Calendar";

const AddTask = () => {
  const [selectedDate, setSelectedDate] = useState<string>(moment().format("YYYY-MM-DD"));

  return (
    <Col lg="12" xl="4">
      <Card className="calendar-box">
        <CardHeader className="border-dash-bottom">
          <H4>{Tasks}</H4>
          <span>Needing Attention</span>
          <div className="card-icon">
            <Btn color="primary" href={Href}>{AddTaskHeading}</Btn>
          </div>
        </CardHeader>
        <CardBody>
          <Calendar onDateSelect={setSelectedDate} />
          <TaskList selectedDate={selectedDate} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default AddTask;
