import { useState, useEffect } from "react";
import { H5, Image, LI } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import UseOutsideDropdown from "../../../../utils/UseOutsideDropdown";
import UserProfileIcons from "./UserProfileIcons";

import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import { getUserProfile } from '../../../../ReduxToolkit/Reducers/UserSlice'

const UserProfile = () => {

  const [show,setShow] =  useState(false)
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(state => state.user.userProfile) 


  useEffect(() => {

    //console.log("CARGANDO PERFIL")
    if(userProfile.email === '') {
      dispatch(getUserProfile())
    }
  }, [])

  //console.log(userProfile)
  const { ref, isComponentVisible, setIsComponentVisible } = UseOutsideDropdown(false);

  return (
    <LI className="profile-dropdown custom-dropdown">
      <div ref={ref} className="d-flex align-items-center" onClick={() => setIsComponentVisible(!isComponentVisible)}>
        {/*<Image src={dynamicImage("profile.png")} alt="avatar" />*/}
        <Image src={userProfile.profile_image} alt="avatar" style={{ width: '45px', height: '40px', objectFit: 'cover' }} />
        <div className="flex-grow-1">
          <H5>{userProfile.first_name}, {userProfile.last_name.charAt(0)}.</H5>
          {/*<span>{userProfile.title}</span>*/}
          <span> Points : 0</span>
        </div>
      </div>
      <div className={`custom-menu overflow-hidden ${isComponentVisible ? "show" : ""}`}>
        <UserProfileIcons />
      </div>
    </LI>
  );
};

export default UserProfile;
