import { useState } from "react";
import { FilePond } from "react-filepond";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { ActualFileUpload, DropFilesHereOrClickToUpload, DropFileUploadText, UploadProjectFiles } from "../../../../utils/Constant";

const UploadProjectFile = () => {
  const [files, setFiles] = useState([]);
  const Data = `<div class="dz-message needsclick"><i class="icon-cloud-up" /><h6>${DropFilesHereOrClickToUpload}</h6><span class="note needsclick">${DropFileUploadText} <strong>not</strong> ${ActualFileUpload}</span></div>`;
  return (
    <Row>
      <Col>
        <FormGroup>
          <Label check>{UploadProjectFiles}</Label>
          <FilePond files={files} allowReorder={true} allowMultiple={true} maxFiles={1} onupdatefiles={() => setFiles} labelIdle={Data} />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default UploadProjectFile;
