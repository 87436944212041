import { Card, CardBody, Col } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "../../../../AbstractElements";
import { sliderOnlySettingData, slidesOnlyData, slidesOnlyDataList } from "../../../../Data/BonusUi/Carousel/Carousel";
import { dynamicImage } from "../../../../Service";
import { DefaultSwiperSlider } from "../../../../utils/Constant";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";

const SlidesOnly = () => {
  return (
    <Col xl="6" xs="12">
      <Card>
        <CardHeaderCommon title={DefaultSwiperSlider} span={slidesOnlyData} headClass="pb-0" />
        <CardBody>
          <Swiper {...sliderOnlySettingData}>
            {slidesOnlyDataList.map((item, index) => (
              <SwiperSlide key={index}>
                <Image src={dynamicImage(item.image)} alt="drawing-room" className="d-block w-100" />
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SlidesOnly;
