import { Card, CardBody, Col } from "reactstrap";
import { fileUploadData } from "../../../../Data/BonusUi/Dropzone/Dropzone";
import { SingleFileUploads } from "../../../../utils/Constant";
import CommonFileUpload from "../../../Common/CommonFileUpload";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";

const SingleFileupload = () => {
  return (
    <Col lg="6">
      <Card>
        <CardHeaderCommon title={SingleFileUploads} span={fileUploadData} headClass="" pb-0 />
        <CardBody>
          <CommonFileUpload />
        </CardBody>
      </Card>
    </Col>
  );
};

export default SingleFileupload;
