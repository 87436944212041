import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { backendURL } from "../../utils/Constant"
const swal = require('sweetalert2')




type tokensType = {
    access: string
    refresh: string
}

type userProfileType = {
    first_name:string
    last_name: string
    bio:string
    title:string
    profile_image:string
    image_ProfileLarge:string
    image_ProfileSmall:string
    image:string
    instagram:string
    twitter:string
    tiktok:string
    otherOnline:string
    fb:string
    website:string
    phone:number
    birthday:string
    email:string
    isSuperUser : boolean
}

type UserSliceType = {
    tokens: tokensType
    data: {
        email:string
        username:string
    }
    userProfile: userProfileType
}

type userLoginType = {
    email:string
    password:string
    goTo: (path:string) => void
}

type registerUserType = {
    name: string
    lastname: string
    email: string
    password: string
}

type editUserProfileType = { 
    full_name?:string 
    bio?:string
    title?:string
    image_ProfileLarge?:File
    image_ProfileSmall?:File
    image?:string | File
    instagram?:string
    twitter?:string
    tiktok?:string
    otherOnline?:string
    fb?:string
    website?:string
    phone?:number
    birthday?:string
    
}

const initialState:UserSliceType = {
    tokens: {
        access: localStorage.getItem('access') ? localStorage.getItem('access') as string : sessionStorage.getItem('access') ? sessionStorage.getItem('access') as string : '',
        refresh: localStorage.getItem('refresh') ? localStorage.getItem('refresh') as string : sessionStorage.getItem('refresh') ? sessionStorage.getItem('refresh') as string : ''
    },
    data: {
        email: "",
        username: ""
    },
    userProfile: {
        first_name: "",
        last_name: "",
        bio: "",
        title: "",
        profile_image: "",
        image_ProfileLarge: "",
        image_ProfileSmall: "",
        image: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        otherOnline: "",
        fb: "",
        website: "",
        phone: 0,
        birthday: "",
        email:"",
        isSuperUser: false
    }
}

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (data:userLoginType, thunkAPI) => {
        console.log("DATA", data);
        
        try {
            const sendData = {
                email: data.email.toLowerCase(),
                password: data.password,
            }
            const response = await axios({
                method: 'POST',
                url: `${backendURL}/api/users/login/`,
                data: sendData
            })
            //console.log("RESPUESTA", response.data)

            return response.data
        } catch (err:any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

type registerFields = {
    data: registerUserType
    goTo: () => void
}
export const registerUser = createAsyncThunk(
    'user/registerUser',
    async (registerData:registerFields, thunkAPI) => {
        try {
            const data = {
                email: registerData.data.email,
                username: registerData.data.name,
                password: registerData.data.password,
                password2: registerData.data.password
            }
            const response = await axios({
                method: 'POST',
                url: `${backendURL}/api/users/register/`,
                data: data
            })
            return response.data
        } catch(err:any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

export const getUserProfile = createAsyncThunk(
    'user/GETUserProfile',
    async (_, thunkAPI) => {
        try {

            
            const response = await axios({
                method: 'GET',
                url:`${backendURL}/api/users/profile/`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
                }
            })
            if (response.status === 401) {
                //REFRESH TOKEN
                await refreshToken()
            }
            //console.log("RESPONSE", response.data);
            return response.data
        } catch(err:any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

export const refreshToken = async () => {
    try {
        const body = {
            refresh: localStorage.getItem("refresh") || sessionStorage.getItem("refresh")
        }
        const response = await axios({
            method: 'GET',
            url: `${backendURL}/users/token/refresh/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `
            },
            data: body
        })
            if(response.status === 200) {
                localStorage.setItem("access", response.data.access)
                sessionStorage.setItem("access", response.data.access)
                localStorage.setItem("refresh", response.data.refresh)
                sessionStorage.setItem("refresh", response.data.refresh)
                window.location.reload()
            }
    } catch(err:any) {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        sessionStorage.removeItem("access")
        sessionStorage.removeItem("refresh")
        window.location.reload()
    }
}

export const editUserProfile = createAsyncThunk(
    'user/editUserProfile',
    async (data:editUserProfileType, thunkAPI) => {
        try {
            if(data.image) data.image = new File([data.image], "image.jpg", {type: "image/jpeg"})
            console.log("editProfile in redux: ", data)
        
            const response = await axios({
                method: 'POST',
                url: `${backendURL}/users/profile/`,
                data: data,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access') || sessionStorage.getItem('access')} `,
                    "Content-Type": "multipart/form-data"
                }
            })
            console.log("RESPUESTA", response.data)

            return response.data
        } catch (err:any) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        //LOGIN
        builder.addCase(loginUser.pending, (state, _) => {
            //console.log("Login process")
        })
        builder.addCase(loginUser.rejected, (state, action:any) => {
            console.log("error", action.payload)
            swal.fire({
                title: "Username or passowrd does not exists",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(loginUser.fulfilled, (state, action:any) => {
            swal.fire({
                title: "Login Successful",
                icon: "success",
                toast: true,
                timer: 2000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            //console.log("ACTION", action)
            localStorage.setItem("access", action.payload.access)
            localStorage.setItem("refresh", action.payload.refresh)
            state.tokens.access = action.payload.access
            state.tokens.refresh = action.payload.refresh
            //console.log("goTo", action.meta.arg.goTo)
            action.meta.arg.goTo("/dashboard/default/")
        })
        //GET USER PROFILE
        builder.addCase(getUserProfile.pending, (state, _) => {
            //console.log("Getting user profile")
        })
        builder.addCase(getUserProfile.rejected, (state, action:any) => {
            console.log(action.payload)
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(getUserProfile.fulfilled, (state, action:any) => {
            //console.log("USER PROFILE", action.payload.first_name);
            state.userProfile = action.payload;
        })
        //EDIT USER PROFILE
        builder.addCase(editUserProfile.pending, (state, _) => {
            swal.fire({
                title: "Updating info",
                icon: "loading",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(editUserProfile.rejected, (state, action) => {
            console.log(action.payload)
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(editUserProfile.fulfilled, (state, action) => {
            swal.fire({
                title: "Profile updated",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            window.location.reload()
        })
        //REGISTER USER
        builder.addCase(registerUser.pending, (state, _) => {
            swal.fire({
                title: "creating user",
                icon: "loading",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(registerUser.rejected, (state, action) => {
            console.log(action.payload)
            swal.fire({
                title: "An error ocurred",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        })
        builder.addCase(registerUser.fulfilled, (state, action) => {
            swal.fire({
                title: "User created",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            action.meta.arg.goTo()
        })
    }
}
)

export default userSlice.reducer