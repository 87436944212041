import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Btn } from "../../../../../AbstractElements";
import { PrintModalPropsTypes } from "../../../../../Type/Application/Contacts/Contacts";
import { Cancel, Print, PrintViews } from "../../../../../utils/Constant";
import PrintPreview from "./PrintPreview";

const PrintModalPreview = ({ printModal, selectedUser, toggleCallback }: PrintModalPropsTypes) => {
  const printModalToggle = () => toggleCallback(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({ contentRef });
  return (
    <Modal fade className="modal-bookmark" isOpen={printModal} toggle={printModalToggle}>
      <ModalHeader toggle={printModalToggle}>{PrintViews}</ModalHeader>
      <ModalBody className="list-persons">
        <PrintPreview selectedUser={selectedUser} ref={contentRef} />
        <Btn color="secondary" className="me-1" onClick={() => handlePrint()}>
          {Print}
        </Btn>
        <Btn color="primary" onClick={printModalToggle}>
          {Cancel}
        </Btn>
      </ModalBody>
    </Modal>
  );
};

export default PrintModalPreview;
