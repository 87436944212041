// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.past-due {
    background-color: red !important;
    color: white !important;
  }
  
  .due-today {
    background-color: indigo !important;
    color: white !important;
  }
  
  .due-today-text {
    color: indigo !important;
    font-weight: bold;
  }
  
  .upcoming {
    background-color: green !important;
    color: white !important;
  }
  
  .past-due-text {
    color: red !important;
    font-weight: bold;
  }
  
  .upcoming-text {
    color: green !important;
    font-weight: bold;
  }

  .current-date {
    border: 2px solid #80808070 !important;
    padding: 2px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/Project/AddTask/Calendar.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,uBAAuB;EACzB;;EAEA;IACE,mCAAmC;IACnC,uBAAuB;EACzB;;EAEA;IACE,wBAAwB;IACxB,iBAAiB;EACnB;;EAEA;IACE,kCAAkC;IAClC,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,sCAAsC;IACtC,YAAY;EACd","sourcesContent":[".past-due {\n    background-color: red !important;\n    color: white !important;\n  }\n  \n  .due-today {\n    background-color: indigo !important;\n    color: white !important;\n  }\n  \n  .due-today-text {\n    color: indigo !important;\n    font-weight: bold;\n  }\n  \n  .upcoming {\n    background-color: green !important;\n    color: white !important;\n  }\n  \n  .past-due-text {\n    color: red !important;\n    font-weight: bold;\n  }\n  \n  .upcoming-text {\n    color: green !important;\n    font-weight: bold;\n  }\n\n  .current-date {\n    border: 2px solid #80808070 !important;\n    padding: 2px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
