import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, ProjectDashboard } from "../../../utils/Constant";
import MyTask from "./MyTask/MyTask";

const ContainerTask = () => {
  return (
    <>
      <Breadcrumbs mainTitle="My Tasks" parent={Dashboard} />
      <Container fluid className="project-dashboard">
        <Row>
          <MyTask />
        </Row>
      </Container>
    </>
  );
};

export default ContainerTask;
