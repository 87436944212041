import { MenuItem } from "../../Type/Layout/Sidebar";

export const MenuList: MenuItem[] = [
  {
    title: "General",
    Items: [
      {
        title: "Dashboard",
        id: 1,
        icon: "Home",
        type: "sub",
        badge:"3",
        children: [
          { path: `${process.env.PUBLIC_URL}/dashboard/task`, title: "My Task", type: "link" }
        ],
      },
      {
        title: "Widgets",
        id: 2,
        icon: "Pie",
        type: "sub",
        active: false,
        children: [
        ]
      }
    ]
  },

  
];