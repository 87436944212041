import { Badges, H5, P } from "../../../../AbstractElements";
import { BlogTimelineTitle, ImplementedTheProgramForWeeklyCodeChallenges } from "../../../../utils/Constant";

const BlogTimeline = () => {
  const BlogTextTime = "help you build problem-solving skills, better understand the programming. ";
  const BlogTime = "If you want to improve your skills in programming.";

  return (
    <div className="cd-timeline-block">
      <div className="cd-timeline-img bg-danger">
        <i className="icon-youtube" />
      </div>
      <div className="cd-timeline-content">
        <div className="timeline-wrapper">
          <Badges color="danger">{BlogTimelineTitle}</Badges>
        </div>
        <H5 className="f-w-500 m-0"> {ImplementedTheProgramForWeeklyCodeChallenges}</H5>
        <P className="mb-0">
          Challenges <em className="font-danger">{BlogTextTime}</em>
          {BlogTime}
        </P>
        <div className="ratio ratio-21x9 m-t-20">
          <iframe src="https://www.youtube.com/embed/sqRk0Ly66Ps" title="myFrame" allowFullScreen></iframe>
        </div>
        <span className="cd-date">March 12 2024</span>
      </div>
    </div>
  );
};

export default BlogTimeline;
