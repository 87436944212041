import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { getTasks, selectTasks } from "../../../../ReduxToolkit/Reducers/TasksSlice";
import moment from "moment";
import "./Calendar.css"; // Import custom CSS for styling

const Calendar: React.FC<{ onDateSelect: (date: string) => void }> = ({ onDateSelect }) => {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const tasks = useSelector(selectTasks);
  const [highlightDates, setHighlightDates] = useState<{ [key: string]: { date: Date; count: number; type: string } }>({});

  useEffect(() => {
    dispatch(getTasks({ status: "active"  }));
  }, [dispatch]);

  useEffect(() => {
    if (tasks.length > 0) {
      const highlights: { [key: string]: { date: Date; count: number; type: string } } = {};
      const now = moment().startOf("day");
      //console.log(tasks);
      
      tasks.forEach(task => {
        console.log(`Status: ${task.status}, Title: ${task.title}, ID: ${task.id}`);
        if (!task.due_date || task.status !== "active") {
          console.log("status is", task.status);
          return;
        };
        
        const dueDate = moment.utc(task.due_date).local().startOf("day"); // Convert from UTC to local time
        if (!dueDate.isValid()) return;
        
        const formattedDate = dueDate.format("YYYY-MM-DD");
        
        if (!highlights[formattedDate]) {
          highlights[formattedDate] = { date: dueDate.toDate(), count: 0, type: "upcoming" };
        }
        highlights[formattedDate].count += 1;
        
        if (dueDate.isBefore(now, "day")) {
          highlights[formattedDate].type = "past-due";
        } else if (dueDate.isSame(now, "day")) {
          highlights[formattedDate].type = "due-today";
        }
      });
      
      setHighlightDates(highlights);
    }
  }, [tasks]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      onDateSelect(moment(date).format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="border-dash-bottom" id="calendar">
     {/* <h2 className="text-xl font-bold text-center mb-4">Task Calendar</h2>*/} 
      
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        inline
        highlightDates={Object.values(highlightDates).map(h => h.date)}
        dayClassName={date => {
          const formattedDate = moment(date).format("YYYY-MM-DD");
          const today = moment().format("YYYY-MM-DD");
          if (formattedDate === today) return "due-today";
          if (highlightDates[formattedDate]?.type === "past-due") return "past-due";
          if (highlightDates[formattedDate]?.type === "due-today") return "due-today";
          if (highlightDates[formattedDate]?.type === "upcoming") return "upcoming";
          return "";
        }}
      />
      
      <p className="text-sm text-center mt-2">
        <span className="past-due-text">Past Due</span> | 
        <span className="due-today-text">Due Today</span> | 
        <span className="upcoming-text">Upcoming</span>
      </p>
    </div>
  );
};

export default Calendar;
