import { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { H3, Image, P } from "../../../../AbstractElements";
import { withCaptionData, withCaptionDataList } from "../../../../Data/BonusUi/Carousel/Carousel";
import { dynamicImage } from "../../../../Service";
import { WithCaption } from "../../../../utils/Constant";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";

const WithCaptions = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Col xl="6" xs="12">
      <Card>
        <CardHeaderCommon title={WithCaption} span={withCaptionData} headClass="pb-0" />
        <CardBody className="mt-2 mb-3">
          <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} loop slidesPerView={1} onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}>
            {withCaptionDataList?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                  <Image src={dynamicImage(slide.image)} alt="drawing-room" className="d-block w-100" />
                  <div className="carousel-caption d-none d-md-block">
                    <H3>{slide.captionHeader}</H3>
                    <P>{slide.captionText}</P>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WithCaptions;
