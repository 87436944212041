import ContainerTask from '../../../Component/Dashboard/Task/Task'

const Task = () => {
  return (
    <div className='page-body'>
      <ContainerTask />
    </div>
  )
}

export default Task