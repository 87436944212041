import { Card, CardBody, Col } from "reactstrap";
import { CrossFades } from "../../../../utils/Constant";
import { crossFadeData, crossFadeDataList } from "../../../../Data/BonusUi/Carousel/Carousel";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";
import { useState } from "react";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Swiper as SwiperType } from "swiper";

const CrossFade = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Col xl="6" xs="12">
      <Card>
        <CardHeaderCommon title={CrossFades} span={crossFadeData} headClass="pb-0" />
        <CardBody>
          <Swiper modules={[Autoplay, EffectFade, Navigation]} effect="fade" navigation loop autoplay={{ delay: 2000 }} slidesPerView={1} onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.realIndex)}>
            {crossFadeDataList?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                  <Image src={dynamicImage(item.image)} alt="drawing-room" className="d-block w-100" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CrossFade;
