import { Badges, H5, P } from "../../../../AbstractElements";
import { AudioTesting, MusicalTrendsAndPredictability } from "../../../../utils/Constant";

const AutoTestingTimeline = () => {
  const AutoText = "So, the next time you listen to music, you might or might not consider how it's actively altering your mood.";

  return (
    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-location bg-info">
        <i className="icon-pulse" />
      </div>
      <div className="cd-timeline-content">
        <div className="timeline-wrapper">
          <Badges color="primary">{AudioTesting}</Badges>
        </div>
        <H5 className="f-w-500 m-0">{MusicalTrendsAndPredictability}</H5>
        <P className="mb-0">{AutoText}</P>
        <audio controls className="mt-3">
          <source src="../assets/audio/horse.ogg" type="audio/ogg" />
        </audio>
        <span className="cd-date">June 12 2024</span>
      </div>
    </div>
  );
};

export default AutoTestingTimeline;
