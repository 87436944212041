export const Href = "#javascript";

// Constants for the project
//export const backendURL = "http://127.0.0.1:8000";
export const backendURL = "https://api.yabash.com";
export const frontendURL = "http://localhost:3001";


// Login Form
export const CreateAccount = "Create Account";
export const DoNotAccount = "Don't have account?";
export const EmailAddress = "Email Address";
export const ForgotPassword = "Forgot password?";
export const Password = "Password";
export const RememberPassword = "Remember password";
export const SignIn = "Sign In";
export const SignInAccount = "Sign in to account";
export const SignInWith = "Or Sign In With";
export const FacebookHeading = "facebook";
export const TwitterHeading = "twitter";
export const LinkedInHeading = "LinkedIn";
export const ThemeColorMode = "Template color mode";
export const SidebarIconHeading = "Sidebar icon:";
export const LayoutTypeHeading = "Layout type:";
export const SidebarTypeHeading = "Sidebar type:";
export const UnlimitedColorHeading = "Unlimited color:";

// Header
export const SearchAnything = "Search anything...";
export const SearchHere = "Search here...";
export const Notifications = "Notifications";
export const View = "View";
export const AllNotification = "All Notification";
export const Bookmark = "Bookmark";
export const AddNewBookmark = "Add New Bookmark";
export const Cart = "Cart";
export const Total = "Total";
export const Checkout = "Checkout";
export const Messages = "Messages";
export const PreviewSettings = "Preview Settings";
export const Document = "Document";

// Sidebar
export const Pinned = "Pinned";

//Default Dashboard
export const Dashboard = "Dashboard";
export const Default = "Default Dashboard";
export const ProjectOverviewHeading = "Project Overview";
export const Weekly = "Weekly";
export const Monthly = "Monthly";
export const Yearly = "Yearly";
export const Project = "Project Dashboard";
export const ViewProject = "View project";
export const Crypto = "Crypto";
export const Ecommerce = "Ecommerce";
export const InvestingHeading = "Investing";
export const AllInvoicesHeading = "All Invoices";
export const TotalInvestmentHeading = "Total Investment";
export const TaskListHeading = "Task list";
export const MonthlyOverviewHeading = "Monthly Overview";
export const TaskSummaryHeading = "Task summary";
export const OurCrew = "Our crew";
export const TeamMembers = "Team Members";
export const Hours = "Hours";
export const CoursesHighlightedHeading = "Courses Highlighted";
export const TotalVisitHeading = "Total visit";
export const TotalEarningHeading = "Total Earning";
export const NotificationsHeading = "Notifications";
export const ScheduleTimeHeading = "Schedule Time";
export const Month = "Month";
export const Year = "Year";
export const Decline = "Decline";
export const Accept = "Accept";

// Ecommerce Dashboard
export const EcommerceDashboard = "Ecommerce Dashboard";
export const TopSellingProductsHeading = "Top Selling Products";
export const CategoryOverviewHeading = "Category Overview";
export const ManageOrderHeading = "Manage Order";
export const SalesSummaryHeading = "Sales Summary";
export const SaleProgressHeading = "Sale Progress";
export const BestSellersHeading = "Best Sellers";
export const SalesByProductHeading = "Sales By Product";
export const CurrentYTD = "Current YTD";
export const ProductOfferHeading = "Product Offer";

// Project Dashboard
export const ProjectDashboard = "Project Dashboard";
export const TotalProjectHeading = "Total Project";
export const TotalRevenueHeading = "Total Revenue";
export const TotalClientsHeading = "Total Clients";
export const AllClients = "All Clients";
export const Invite = "Invite";
export const ProjectsOverviewHeading = "Projects Overview";
export const Tasks = "Tasks";
export const Complete = "complete";
export const Reminder = "Reminder";
export const TodoList = "To-do list";
export const Today = "Today";
export const ActivityReportHeading = "Activity report";
export const Progress = "Progress";
export const TeamMembersHeading = "Team Members";
export const AddTaskHeading = "Add Task";
export const TodoPlaceholder = "Enter new task here. . .";

// Widgets
export const Widgets = "Widgets";
export const General = "General";
export const TotalSaleHeading = "Total Sale";
export const WebsiteDesigns = "Website Design";
export const SquareDashboard = "Square Dashboard";
export const Design3D = "3D Design";
export const Visitor = "Visitors";
export const UpcomingAppointments = "Upcoming Appointment";
export const MonthlyHistorys = "Monthly History";
export const SkillStatusHeading = "Skill Status";
export const OrdersStatusHeading = "Order Status";
export const LiveProduct = "Live Products";
export const TurnOver = "Turn over";
export const CryptocurrencyPrice = "Cryptocurrency Prices";
export const StockMarkets = "Stock Market";
export const Finances = "Finance";
export const MonthlySale = "Monthly Sales";
export const CryptoAnnotation = "Crypto Annotations";
export const OrdersStatus = "Order Status";
export const Uses = "Uses";
export const StudyStatistic = "Study Statistics";
export const UXDesign = "UX Design";
export const Illustrations = "Illustrations";
export const Assignments = "Assignments";
export const LiveMeetings = "Live Meeting";
export const ActivelyHour = "Actively Hours";
export const EnrolledClass = "Enrolled Classes";
export const MonthlyAttendances = "Monthly Attendance Report (Feb)";
export const Schedules = "Schedule";
export const FeaturedCourse = "Featured Courses";
export const Keyboard = "Keyboard";
export const Laptops = "Laptops";
export const Desktop = "Desktop";
export const Mouse = "Mouse";
export const Chart = "Chart";

// Ui-kits
export const UiKits = "Ui Kits";
export const Typography = "Typography";
export const Headings = "Headings";
export const Code = "Code";
export const FontSize = "Font Size";
export const Heading = "Heading";
export const ColoredHeadings = "Colored Headings";
export const VerticalAlignment = "Vertical Alignment";
export const GridForColumn = "Grid For Column";
export const FontWeight = "Font Weight";
export const FontWeightTitle = "You can set bolder font weight Heading";
export const ListingTypography = "Listing Typography";
export const UnOrderList = "Unorder List";
export const OrderLists = "Order Lists";
export const DescriptionLists = "Description Lists";
export const DisplayHeadings = "Display Headings";
export const InlineTextElements = "Inline Text Elements";
export const TextColor = "Text Color";
export const Blockquote = "Block quotes";
export const Avatar = "Avatars";
export const Sizes = "Sizes";
export const StatusIndicator = "Status Indicator";
export const Shapes = "Shapes";
export const Ratio = "Ratio";
export const Grouping = "Grouping";
export const TagAndPills = "Tag & Pills";
export const BadgesContextualVariations = "Badges Contextual Variations";
export const PillsContextualVariations = "Pills Contextual Variations";
export const NumberOfBadge = "Number of Badge ";
export const NumberOfPillsTags = "Number of Pills Tags";
export const BadgeTagsWithIcons = "Badge Tags With Icons";
export const RoundedPillsWithIcons = "Rounded Pills With Icons";
export const BadgeHeadingsExample = "Badge Headings Example";
export const BadgesAsPartButtons = "Badges As Part Buttons";
export const Pixel = "Pixel";
export const GroupingWithCounterHeading = "Grouping With Counter";

// Ui-kits
export const Grid = "Grid";
export const HelperClasses = "Helper Classes";
export const StylerBorders = "Styler in Borders";
export const CustomBorderRadiusClass = "Custom border-radius class";
export const BorderColor = "Border color";
export const BorderWidth = "Border-width";
export const BackgroundColors = "Background colors";
export const TextColors = "Text colors";
export const AdditiveBorder = "Additive Border";
export const SubtractiveBorder = "Subtractive Border";
export const AdditiveRadiusHeading = "Additive Radius";
export const DarkBackground = "Dark Background";
export const LightBackground = "Light Background";
export const BordersAndDisplays = "Borders and displays";
export const ExtendedBackgroundColor = "Extended Background Color";
export const Padding = "Padding";
export const PaddingLeft = "Padding Left";
export const MarginLeft = "Margin Left";
export const GridOptions = "Grid options";
export const GridBehavior = "Grid behavior";
export const HorizontalAllTimes = "Horizontal at all times";
export const CollapsedBreakpoints = "Collapsed to start, horizontal above breakpoints";
export const MaxContainerWidth = "Max container width";
export const ClassPrefix = "Class prefix";
export const Class = " Class";
export const ValueOfClass = "Value of the class";
export const VerticalAlignmentClass = ".align-items-*";
export const VerticalAlignmentValueClass = " start / center / end / baseline / stretch";
export const HorizontalAlignment = "Horizontal Alignment";
export const HorizontalAlignmentValueClass = "start / center / end / around / between";
export const HorizontalAlignmentClass = ".justify-content-*";
export const Nesting = "Nesting";
export const Order = "Order";
export const OrderClass = ".order-*";
export const OrderValueClass = " first / 2 / 3 / 4 / 5 / last";
export const Offset = "Offset";
export const OffsetClass = ".offset-*";
export const OffsetValueClass = " 1 / 2 / ... / 11 / 12";
export const TotalSell = "Total Sells";
export const OrdersValue = "Orders Value";
export const DailyOrders = "Daily Orders";
export const DailyRevenue = "Daily Revenue";
export const SalesOverviews = "Sales Overview";
export const FontSizes = "Font Sizes";
export const RecentCustomer = "Recent Customers";
export const RevenueByCategorys = "Revenue By Category";
export const UserContinent = "User by Continent";
export const AllOverContriesSales = "All Over Contries's Sale";
export const Color = "Color";
export const ImagesSizes = "Images Sizes";
export const FontStyle = "Font-Style";
export const SpecialDiscount = "Special Discount";
export const Days = "Days";
export const Margin = "Margin";
export const Min = "Min";
export const Sec = "Sec";
export const Topsellermonth = "Top seller of the month";
export const RecentOrder = "Recent Orders";
export const DealDayFrom = "Deal of the Day From ";
export const SelectSize = "Select size";
export const OnlyOneSidePadding = "Only one side padding";
export const OnlyOneSideMargin = "Only one side margin";
export const TouchspinBadgesHeading = "Touchspin Badges";
export const AnimatedBadgesHeading = "Animated Badges";
export const CenterBadgesHeading = "Center Badges";

//Ui Kits- Progress
export const BasicProgressBars = "Basic progress bars";
export const ProgressBarsStriped = "Progress bars striped";
export const ProgressBarsAnimated = "Progress bars animated";
export const MultipleBars = "Multiple bars";
export const ProgressWithNumberSteps = "Progress with number steps";
export const CustomProgressBars = "Custom progress bars";
export const SmallProgressBars = "Small progress bars";
export const LargeProgressBars = "Large progress bars";
export const GettingStarted = "0% Getting Started ";
export const CustomHeightProgressBars = "Custom height progress bars";

//Ui Kits- Popover
export const Popover = "Popover";
export const HurryUps = "Hurry Up!";
export const BasicPopovers = "Basic popover";
export const PopoverOnTop = " Popover On Top";
export const PopoverOffsets = "Popover offset ";
export const PopoverDirections = "Popover direction";
export const PopoverOffset50 = "Popover offset 50";
export const PopoverTitle = "Popover Title";

//Ui Kits- Tooltip
export const Tooltip = "Tooltip";
export const TooltipSmall = "tooltip";
export const BasicTooltip = "Basic tooltip";
export const MagicPleaseHoverMe = "It's magic please hover me...";
export const InlineTooltipContent = "Inline tooltip Content";
export const ColoredTooltips = "Colored tooltip ";
export const DirectionsTooltip = "Tooltip  directions";
export const TooltipOnTop = "Tooltip on tops";
export const HTMLElementsWithHoverEffect = "HTML Elements With Hover Effect";
export const NotificationsReceived = "Notifications Received";
export const FilledTooltips = "Filled tooltip";
export const TooltipPrimary = "Tooltip Primary";
export const ThankYouBold = "<b>Thank</b> <em>you</em>";
export const ThankYouItalic = "<i>Thank </i> <u>you</u>";
export const Primary = "Primary";
export const ButtonSmall = "button";
export const WonkyTooltipHeading = "Wonky Tooltip";
export const Username = "Username";
export const ImageTooltipHeading = "Image Tooltip";

//Ui Kits- Alert
export const Alert = "Alert";
export const LinkColorDarkTheme = "Link With Dark Color Alerts";
export const LinkColorLightTheme = "Link With Light Color Alerts";
export const OutlineDarkLightAlerts = "Color Alert With Outline";
export const AlertsWithIconsTextActions = "Alerts With Icons And Text Actions";
export const DismissingDarkAlert = "Dismissing dark alert";
export const DismissingLightAlert = "Dismissing light alert";
export const LiveAlerts = "Live alert";
export const ShowLiveAlert = "Show live alert";
export const LeftBorderAlerts = "Border direction alert";
export const AdditionalContents = "Additional content";
export const PleaseCheckYourNotifications = "Please! Check your notifications";
export const Check = "Check";

//Ui Kits- Modal
export const Modal = "Modal";
export const BasicModal = "Basic Modal";
export const Simple = "Simple";
export const ScrollingContent = "Scrolling content";
export const UXDesigners = "UX Designer ";
export const WebDesigns = "Web Design ";
export const TooltipsAndPopovers = "Tooltips and popovers";
export const PopoverModal = "Popover in a modal";
export const TooltipsModal = "Tooltips in a modal";
export const OpenModalForEdmin = "Open modal for Edmin";
export const SizesModal = "Sizes Modal";
export const FullScreenModals = "Full Screen Modal";
export const ExtraLargeModals = "Extra large Modal";
export const LargeModals = "Large Modal";
export const SmallModals = "Small Modal";
export const FullScreenModal = "Fullscreen Modal";
export const FullScreenBelowSM = "Full Screen Below sm";
export const FullScreenBelowMD = "Full Screen Below md";
export const FullScreenBelowLG = "Full Screen Below lg";
export const FullScreenBelowXL = "Full Screen Below xl";
export const FullScreenBelowXXL = "Full Screen Below xxl";
export const CenteredModals = "Centered Modal ";
export const VerticallyCentered = "Vertically centered";
export const ToggleBetweenModal = "Toggle Between Modals ";
export const OpenFirstModal = "Open first Modal ";
export const StaticBackdropModals = "Static Backdrop Modal";
export const EdminCustomModal = "Edmin Custom Modals ";
export const BalanceAmount = "$768,987.90";
export const Close = "Close";
export const SaveChanges = "Save changes";
export const ClickOut = "Click Out";
export const EdminSignUp = "Edmin SIGN-UP";
export const EdminLogin = "Edmin Login";
export const StaticBackdropModalBtn = "Static backdrop modal";
export const SomethingWentWrong = "Ohh! Something went wrong!";
export const AlreadyLeaving = "Already leaving??";
export const YesLogOut = "Yes, Log out";
export const ParentToggleTitle = "Remove your complete account from your phone or tablet to sign out of the Gmail app.";
export const ConnectNewAccount = "Connect new account";
export const ConnectNewRegisterAccount = "Register";
export const SignUp = "Sign Up";
export const EdminEmail = "Edmintheme@gmail.com";
export const EnterYourFirstName = "Enter your first-name";
export const EnterYourLastName = "Enter your last-name";
export const TermsAndCondition = "You accept our Terms and Privacy Policy by clicking Submit below.";
export const ExtraLargeModal = "Extra large modal";
export const WebDesign = "Web Design";
export const StartWithYourGoals = "Start with your goals";
export const FullscreenModal = "Fullscreen modal";
export const WebDesigner = "Web Designer";
// export const ButtonSmall= "Button Small"
export const TooltipPopoverModal = "Tooltip and Popover Modal";
export const ScrollingModalHeading = "Scrolling Modal";
export const ExploreMore = "Explore More";
export const Cancel = "Cancel";
export const CheckMeOut = "Check me out";
export const Email = "Email";
export const EmailPlaceHolder = "Enter Your Email";
export const PasswordPlaceholder = "Enter Your Password";
export const Save = "Save";
export const FirstName = "First name";
export const LastName = "Last name";

//Ui Kits - Dropdown
export const DropdownTitle = "Dropdown";
export const Dropdown = "Dropdown";
export const BasicDropdowns = "Basic dropdown";
export const RoundedDropdowns = "Rounded dropdown";
export const SplitDropdowns = "Split dropdown";
export const Party = "Party";
export const Inputs = "Inputs";
export const HeadingDropdowns = "Heading dropdown";
export const WithInputTypes = "With input type";
export const DarkDropdowns = "Dark dropdown";
export const DarkNight = "Dark Night";
export const UniqueDropdownHeading = "Unique dropdown";
export const DropdownForms = "Dropdown form";
export const Text = "Text";
export const JustifyContent = "Justify contents";
export const TextLeft = "Text-left";
export const Alignment = "Alignments";
export const WarningTop = "Warning top";
export const HelperCards = "Helper card";
export const LearnMore = "Learn More!";
export const WarningCards = "Warning Card";
export const Warning = "Warning!";
export const AlertCards = "Alert Card";
export const Danger = "Danger";
export const DividerDropdowns = "Divider dropdown";
export const DropdownSizings = "Dropdown sizing";
export const LargeButton = "Large button";
export const RememberMe = "Remember me";
export const EmailPlaceholder = "email@example.com";
export const Wishlist = "Wishlist";

//Ui Kits - Accordion
export const Accordion = "Accordion";
export const SimpleAccordions = "Simple accordion";
export const FlushAccordions = "Flush accordion";
export const WhatDoWebDesignersDo = "What do web designers do ?";
export const MultipleCollapseAccordions = "Multiple collapse accordion";
export const ToggleFirstElement = "Toggle first element";
export const ToggleSecondElement = "Toggle second element";
export const ToggleBothElement = "Toggle both element";
export const WithIconsAccordions = "With icons accordion";
export const OutlineAccordions = "Nested Accordion ";
export const HorizontalAccordions = "Horizontal accordion";
export const ToggleWidthCollapse = "Toggle with collapse";
export const CollapseAccordions = "Different tag accordion";
export const LinkWithHref = "Link with href";
export const ButtonWithDataBsTarget = "Button with data-bs-target";

//Ui Kits Tabs
export const ReactstrapTabs = "Reactstrap Tabs";
export const Tabs = "Tabs";
export const SimpleTab = "Simple tabs";
export const IconsWithTab = "Icons With tabs";
export const VerticalTab = "Vertical tabs";
export const PillsTab = "Pills tabs";
export const JustifyTab = "Justify tabs";
export const MaterialStyleLeftTab = "Material style left tabs";
export const DalbultCaslin = "Dalbult Caslin ";
export const MaterialStyleTab = "Material style tabs";
export const BorderTab = "Border tabs ";
export const Contact = "Contact";
export const Components = "Components";
export const PageTab = "Page";
export const AboutUs = "About us";
export const Blog = "Blog";
export const UxDesigner = "UX designer";
export const IOTDeveloper = "IOT developer";
export const User = "User";
export const Review = "Review";
export const ExampleTextarea = "Example textarea";
export const Home = "Home";
export const Profile = "Profile";
export const ContactUs = "Contact us";
export const Settings = "Settings";
export const Inbox = "Inbox";
export const Description = "Description";

//Ui Kits - Lists
export const Lists = "Lists";
export const DefaultList = "Default lists ";
export const ActiveList = "Active lists ";
export const FlushList = "Flush lists";
export const ContextualClassesHeading = "Contextual classes";
export const HorizontalList = "Default Lists";
export const CustomContentList = "Custom content lists";
export const MollyBoake = "Molly Boake";
export const ListsWithCheckboxs = "Lists with checkbox";
export const ListsWithRadio = "Lists with radios";
export const ListsWithNumber = "Lists with numbers";
export const JavaScriptBehaviors = "JavaScript behavior";
export const NumberedBadgeList = "Numbered & badge lists";
export const DisabledList = "Disabled lists ";
export const ScrollableList = "Scrollable lists ";

//Bonus Ui - Scrollable
export const BonusUi = "Bonus Ui";
export const Scrollable = "Scrollable";
export const CustomScrollbars = "Custom Scrollbar";
export const SmallSizeScrollbars = "Small Size Scrollbar";
export const SolutionBusinessTransformation = "Solution for Business Transformation";
export const BadgesScrollbars = "Badges Scrollbar";
export const ProfileScrollables = "Profile Scrollable";
export const ScrollableContents = "Scrollable Content";
export const HorizontalScrollbars = "Horizontal Scrollbar ";
export const BothSideVisibleScrollbars = "Both Side Visible Scrollbar";

//Breadcrumb
export const BreadcrumbHeading = "Breadcrumb";
export const BreadcrumbHome = "Home";
export const BreadcrumbUiKits = "Ui Kits";
export const BreadcrumbAlert = "Alert";
export const BreadcrumbProgress = "Progress";
export const BreadcrumbBonusUi = "Bonus Ui";
export const BreadcrumbTable = "Table";
export const BreadcrumbBootstrapTable = "Bootstrap Tables";
export const DefaultBreadcrumbs = "Default Breadcrumb";
export const DividerBreadcrumbs = "Divider Breadcrumb";
export const WithIconsBreadcrumbs = "With Icons Breadcrumb";
export const VariationOfBreadcrumbs = "Variation Of Breadcrumb";
export const ColoredBreadcrumbs = "Colored Breadcrumb";
export const OutlineBreadcrumbHeading = "Outline Breadcrumb";

//Pagination
export const Pagination = "Pagination";
export const DefaultPaginations = "Default Pagination";
export const PaginationWithActiveAndDisabled = "Pagination With Active And Disabled";
export const PaginationWithIcon = "Pagination With Icons";
export const RoundedPaginations = "Rounded Pagination";
export const PaginationAlignments = "Pagination Alignment";
export const PaginationSizings = "Pagination Sizing";
export const Previous = "Previous";
export const Next = "Next";

//Bonus Ui - Ribbons
export const Ribbons = "Ribbons";
export const VariationsOfLeftRibbons = "Variations Of Left Ribbons";
export const VariationsOfRightRibbons = "Variations Of Right Ribbons";
export const RibbonLeftText2 = "to identify your items.";
export const CssRibbons = "CSS ribbons ";

//Bonus Ui - Tree View
export const TreeView = "Tree View";
export const BasicTree = "Basic View";
export const DisabledTrees = "Disabled Tree";

//Bonus Ui - Toasts
export const Toasts = "Toasts";
export const LiveToasts = "Live Toast";
export const TopRightToasts = "Top-right toast";
export const BottomRightToasts = "Bottom-right toast";
export const TopLeftToasts = "Top-left toast";
export const BottomLeftToasts = "Bottom-left toast";
export const YourAccountWillBePermanentlyDeleted = "Your account will be permanently deleted?";
export const ImNotSure = "I'm not sure";
export const RemoveMyAccount = "Remove My Account";
export const ColorsScheme = "Colors Schemes";
export const StackingToast = "Stacking Toasts";
export const TranslucentToast = "Translucent Toasts";
export const DefaultToasts = "Default Toast";
export const UniqueToasts = "Unique Toast";
export const ToastPlacements = "Toast Placement";

//Bonus Ui - Dropzone
export const Dropzone = "Dropzone";
export const DefaultFileUploads = "Default File Upload";
export const PreviewImage = "Image Preview ";
export const SingleFileUploads = "Single File Upload";
export const MultiFileUploads = "Multi File Upload";

//Bonus Ui - Tour
export const Tour = "Tour";
export const WilliamJennings = "William C. Jennings";
export const WebDesignerTour = " Web Designer";
export const FollowerTour = "Follower";
export const FollowingTour = "Following";
export const WonderFullTour = "Wonderful piece that successfully conveys the magnificence of the mountains and the natural world.";
export const ReadMoreTour = "ReadMore";
export const PainterTourTitle = "English Romantic painter, printer, and watercolourist ";

//Bonus Ui - Sweet Alerts
export const SweetAlert = "Sweet Alert";
export const BasicExamples = "Basic Sweetalert";
export const ClickIt = "Click it!";
export const TitleWithText = "Title with text!";
export const Surprise = "Surprise!";
export const TitleWithATextUnder = "Title With A Text Under";
export const InfoAlerts = "Info Alert";
export const Info = "info";
export const Informational = "Informational";
export const WarningAlerts = "Warning Alert !";
export const SweetWarningButton = "Warning";
export const PikachuAlerts = "Pikachu Alert";
export const Pikachu = "Pikachu";
export const QuestionsAlerts = "Questions Alert ";
export const QuestionsState = "Questions state";
export const UsernameAlerts = "Username Alert";
export const SuccessMessages = "Success Message";
export const LoginSuccessfully = "Login successfully";
export const DangerAlerts = "Danger Alert ";
export const DangerMode = "Danger mode";
export const WarningModes = "Warning Mode";
export const AlertMode = "Alert mode";
export const Timer = "Timer";
export const AjaxRequestMovies = "AJAX Request Movie";
export const MovieMode = "Movie mode";
export const AdvanceSweetalertHeading = "Advance Sweetalert";
export const UniqueSweetalertHeading = "Unique Sweetalert";
export const AnimationAlert = "Animation Alert";
export const DirectionSweetalertHeading = "Direction Sweetalert";
export const TopLeft = "Top Left";
export const TopRight = "Top Right";
export const BottomLeft = "Bottom Left";
export const BottomRightBtn = "Bottom Right";
export const InputSweetalertHeading = "Input sweetalert";
export const TextInput = "Text Input";
export const LoginEmail = "Login Email";
export const URL = "URL";
export const Textarea = "Textarea";
export const SelectInput = "Select Input";
export const RadioInput = "Radio Input";
export const CheckboxInput = "Checkbox Input";
export const Range = "Range";
export const GithubAvatarHeading = "Github Avatar";
export const AjaxAlert = "Ajax alert";
export const RTLSupportHeading = "RTL Support";
export const RTLAlert = "RTL Alert";
export const MessageTimerHeading = "Message Timer";
export const ModalWithImagesHeading = "Modal with Images";

//Bonus Ui - Carousel
export const Slider = "Slider";
export const Carousel = "Carousel";
export const DefaultSwiperSlider = "Default Swiper Slider";
export const SlideOnly = "Slides Only";
export const WithControl = "With Controls";
export const AutoPlayVariants = "Auto Play Variant";
export const MouseWheelVariants = "Mouse Wheel Variant";
export const MouseOverTitle = "Mouse Wheel Variant";
export const WithIndicator = "With Indicators";
export const WithCaption = "With Captions";
export const CrossFades = "Cross-Fade";
export const IndividualCarouselItemIntervals = "Individual Carousel-Item Interval";
export const DisableTouchSwipings = "Disable Touch Swiping";
export const DarkVariants = "Dark Variant";

//Bonus Ui - Range Slider
export const RangeSlider = "Range Slider";
export const DefaultRangeSliders = "Default Range Slider";
export const MinMaxValues = "Min Max Value";
export const NegativeValues = "Negative Value";
export const Disable = "Disabled";
export const Prefixs = "Prefix";
export const FormatedLabels = "Manipulating UI";

//Bonus Ui - Image-Cropper
export const ImageCropper = "Image Cropper";
export const HiddenDownload = "Hidden download";
export const DownloadCrop = "Download Crop";

//Bonus Ui - Basic Card
export const BasicCardHeading = "Basic Card";
export const FlatCards = "Edge Card";
export const WithoutShadowCards = "Without shadow Card";
export const EdminTheme = "Edmin Template";
export const IconInHeadings = "Icon In Heading";
export const DarkColorCards = "Dark Color Card";
export const FooterCard = "Card Footer";
export const SmartHeadphones = "Smart headphones";

//Bonus Ui - Creative Card
export const CreativeCard = "Creative Card";
export const BorderLefts = "Border Left";
export const BorderRights = "Border Right";
export const TopBorder = "Border Top";
export const BottomBorder = "Border Bottom";
export const TeresaMosteller = "Teresa J. Mosteller";
export const GloriaAcheson = "Gloria D. Acheson";
export const BryanOwens = "Bryan A. Owens";
export const PrimaryBorderState = "Border Primary State";
export const WarningBorderState = "Border Warning State";
export const SecondaryBorderState = "Border Secondary State";
export const EmailAddressPlaceHolders = "youremail@gmail.com";
export const Timeline = "Timeline";
export const AbsoluteCards = "Absolute Card";

//Bonus Ui - Timeline
export const ConferenceWithClient = "CONFERENCE WITH CLIENT";
export const HoveringTimelines = "Hovering Timeline";
export const BasicTimelines = "Basic Timeline";
export const Conference = "Conference";
export const MeetUp = "Meet-up";
export const MeetUpTimelineTitle = "Meet-up";
export const TimelineTitle = "Timeline";
export const Location = "Location";
export const AppIdeas = "app-ideas";
export const EstablishedNewTheAppIdeaRepository = "Established new the app-ideas repository.";
export const ViewItOnGithub = "View it on Github ";
export const BlogTimelineTitle = "Blog";
export const VariationTimelines = "Variation Timeline";
export const UpdatedProduct = "Updated Product";
export const ReadMore = "Read More";
export const HorizontalTimelines = "Horizontal Timeline";
export const ImplementedTheProgramForWeeklyCodeChallenges = "Implemented the program for weekly code challenges.";
export const AudioTesting = "Audio testing";
export const MusicalTrendsAndPredictability = "Musical trends and predictability";
export const WebDesignersMeeUp = "Web-designer's meet-up";
export const PleaseMeetUp = "Please! Meet-up";
export const MyResolutions = "My Resolutions";
export const MyResolutionsFor2024 = "My Resolutions for 2024";
export const USMeeting = "US Meeting";
export const Resolutions = "Resolutions";
export const CarouselText = "Research about most recent design trends.";
// Users
export const BOD = "BOD";
export const Followers = "Follower";
export const Following = "Following";
export const Like = "Like";
export const AboutMe = "About Me";
export const Website = "Website";
export const MyProfile = "My Profile";
export const Bio = "Bio";
export const EditProfile = "Edit Profile";
export const Company = "Company";
export const UpdateProfile = "Update Profile";
export const AddprojectAndUpload = "Add project And Upload";
export const ProjectName = "Project Name";
export const Status = "Status";
export const Update = "Update";
export const DesignerTitle = "Designer";
export const Date = "Date";
export const Delete = "Delete";
export const Edit = "Edit";
export const Emailaddress = "Email address";
export const PostalCode = "Postal Code";
export const Posts = "Posts";
export const Comments = "Comment";
export const Users = "Users";
export const UsersCards = "User Cards";
export const UsersProfile = "User Profile";

//Bonus Ui - Rating
export const RatingBars = "1 to 10 Rating Bar";
export const MovieRatingBar = "Movie Rating Bar";
export const SquareRatingBar = "Square Rating Bar";
export const PillRatingBar = "Pill Rating Bar";
export const ReversedRatingBar = "Reversed Rating Bar";
export const StarRatingBar = "Star Rating Bar";
export const HalfStarRatingBar = "Half Star Rating Bar";
export const ThumbUpDownRate = "Thumb Up/Down Rating Bar";
export const HeartRatingBar = "Heart Rating Bar";
export const Rating = "Rating";

//Icon
export const Icons = "Icons";
export const FlagIcon = "Flag Icons";
export const FontAwesomeIcons = "Font Awesome Icon";
export const Currency = "Currency";
export const WebApp = "Web App";
export const Spinner = "Spinner";
export const FormControl = "Form Control";
export const TextEditor = "Text Editor";
export const Directional = "Directional";
export const VideoPlayer = "Video Player";
export const BrandPlayer = "Brand Player";
export const Medical = "Medical";
export const IcoIcon = "ICO Icon";
export const Abstract = "Abstract";
export const Sport = "Sport";
export const Social = "Social";
export const Weather = "Weather";
export const WebApplication = "Web Application";
export const Travel = "Travel";
export const Transport = "Transport";
export const Multimedia = "Multi media";
export const MobileUI = "Mobile UI";
export const Mathematical = "Mathematical";
export const Animal = "Animal";
export const Construction = "Construction";
export const Device = "Device";
export const Education = "Education";
export const FileType = "File Type";
export const Food = "Food";
export const Law = "Law";
export const Emoticon = "Emoticon";
export const KidsToys = "Kids & Toys";
export const Payment = "Payment";
export const Person = "Person";
export const ThemifyIcon = "Themify Icon";
export const ArrowsDirection = "Arrows & Direction ";
export const Control = "Control";
export const FeatherIcons = "Feather Icons";
export const WhetherIcon = "Whether Icon";
export const WhetherIconsWithAnimations = "Whether Icons With Animations";
export const Markup = "Markup";
export const CopyText = "Copy text";
export const IconlySpriteHeading = "Iconly Sprite";
export const IconlyIcons = "Iconly Icons";

// Buttons
export const Buttons = "Buttons";
export const ButtonsStyleHeading = "Buttons Style";
export const ButtonsDescriptionHeading = "Buttons Description";
export const ButtonsSizeHeading = "Buttons Size";

//Charts - Apex charts
export const Charts = "Charts";
export const ApexCharts = "Apex charts";
export const BasicAreaCharts = "Basic Line Chart ";
export const AreaSpalineCharts = "Basic Area Chart";
export const BarCharts = "Dumbbell chart";
export const ColumnCharts = "Column Chart ";
export const BubbleCharts = "3d Bubble Chart";
export const SteplineCharts = "Stepline Chart ";
export const ChartColumn = "Column Chart";
export const PieCharts = "Pie Chart ";
export const DonutChart = "Donut Chart";
export const MixedCharts = "Mixed Chart";
export const CandlestickCharts = "Candlestick Chart ";
export const RadarCharts = "Radar Chart";
export const RadialBarCharts = "Radial Bar Chart";
export const PyramidChartHeading = "Pyramid chart";
export const PolarChartHeading = "Polar chart";

//Charts - Google charts
export const GoogleCharts = "Google charts";
export const AreaChartOne = "Area Chart 1";
export const ColumnChartOne = "Column Chart 1";
export const AreaChartTwo = "Area Chart 2";
export const ColumnChartTwo = "Column Chart 2";
export const GanttCharts = "Gantt Chart";
export const LineCharts = "Line Chart";
export const ComboCharts = "Combo Chart";
export const BarChartTwo = "bar-chart2";
export const WordTreeChart = "word tree";
export const PieChartOne = "Pie Chart 1";
export const PieChartTwo = "Pie Chart 2";
export const PieChartThree = "Pie Chart 3";
export const PieChartFour = "Pie Chart 4";

//Charts - ChartJS charts
export const ChartJSCharts = "ChartJS";
export const ChartJSBarChart = "Basic Bar Chart";
export const ChatJSLineGraph = "Basic Line Chart";
export const ChatJSRadarGraph = "Radar Graph";
export const ChatJSLineChart = "Line Chart";
export const ChatJSPolarChart = "Polar Chart";

//Checkbox & Radio
export const FormsControls = "Forms Controls";
export const CheckboxAndRadio = "Checkbox & Radio";
export const DefaultCheckboxHeading = "Default checkbox";
export const DefaultCheck = "Default Checks";
export const DefaultCheckBox = "Default checkbox";
export const CheckedCheckbox = "Checked checkbox";
export const DisabledChecks = "Disabled Checks";
export const IndeterminateHeading = "Indeterminate";
export const IndeterminateCheckbox = "Indeterminate checkbox";
export const CustomCheckboxHeading = "Custom checkbox";
export const BorderedCheckboxHeading = "Bordered Checkbox ";
export const PrimaryCheckboxPrimary = "Primary - checkbox-primary";
export const IconCheckboxHeading = "Icon Checkbox ";
export const FilledCheckboxHeading = "Filled Checkbox";
export const WarningCheckboxSolidWarning = "Warning - checkbox-solid-warning ";
export const DefaultRadios = "Default radio";
export const CustomRadio = "Custom Radios";
export const DefaultRadioLabel = "Default radio";
export const DefaultCheckedRadio = "Default checked radio";
export const ImagesWithCheckboxs = "Images with checkbox";
export const ImagesWitRadio = "Images with radio";
export const Custom = "Custom";
export const CustomRadios = "Custom radio";
export const BorderedRadios = "Bordered Radio";
export const EdminTemplate = "Edmin Template";
export const IconsRadios = "Icons Radio";
export const FilledRadios = "Filled Radio";
export const ProductRadio = "Product";
export const DefaultSwitch = "Default switches";
export const CustomSwitches = "Custom Switches";
export const DefaultSwitchCheckboxInput = "Default switch checkbox input";
export const CheckedSwitchCheckboxInput = "Checked switch checkbox input";
export const InlineInputType = "Inline input-types";
export const InlineCheckboxHeading = "Inline Checkbox";
export const InlineRadio = "Inline Radios";
export const InlineSwitches = "Inline Switches";
export const AnimatedButton = "Animated buttons";
export const SelectYourPaymentMethods = "Select your payment method";
export const VisaAnimated = "Visa";
export const InstagramAnimated = "Instagram";
export const WhatIsYourFavoriteSocialMedia = "What is your favorite social media?";
export const BasicRadioAndCheckboxHeading = "Basic radio and checkbox";
export const SimpleCheckboxHeading = "Simple Checkbox";
export const BasicRadioAndCheckboxBlog = "Blog";
export const BasicRadioAndCheckboxSimpleRadios = "Simple Radios";
export const BasicRadioAndCheckboxMaps = "Maps";
export const RadioToggleButton = "Radio toggle buttons";
export const RadioChecked = "Checked";
export const OutlinedCheckboxStyle = "Outlined checkbox styles";
export const SingleToggle = "Single toggle";
export const ButtonChecked = "Checked";
export const ButtonCheckedSuccessRadio = "Checked success radio";
export const ButtonDarkRadio = "Dark radio";

//Base Input
export const BaseInputs = "Base inputs";
export const FormBasics = "Simple Example";
export const EmailLabel = "Email";
export const PasswordLabel = "Password";
export const SignInButton = "Sign in";
export const FormFloatingHeading = "Floating form";
export const FormControlSizingPlaceholder = ".form-control-lg";
export const EmailFloatingPlaceholder = "name@example.com";
export const PasswordFloatingPlaceholder = "Password";
export const FloatingPasswordPlaceholder = "Password";
export const SelectSizings = "Select sizing";
export const WhatsYourHobbies = "What's Your Hobbies";
export const Sizings = "Sizing";
export const FormControlSizingsPlaceholder = ".form-control-sm";
export const FileInputs = "File input";
export const FlatInputStyles = "Flat input style";
export const File = "File";
export const SmallFileInputExample = "Small file input example";
export const LargeFileInputExample = "Large file input example";
export const SelectYourFavoriteRomanNumber = "Select your favorite roman number";
export const SelectYourMultiplePaintings = "Example multiple select";
export const PleaseDoComments = "Please do comments";
export const BasicHtmlInputControls = "Basic Input Type Control";
export const BasicPlaceholder = "Place holder";
export const BasicPlaceholderPlace = "This is for hint only ..!";
export const BasicPassword = "Password";
export const PasswordInputPlaceholder = "Password input";
export const BasicNumber = "Number";
export const NumberPlaceholder = "Number";
export const BasicTelephone = "Phone Number";
export const BasicTelephonePlacholder = "91-(999)-999-999";
export const URLs = "URL";
export const URLsValue = "https://getbootstrap.com";
export const BasicDateAndTime = "Date and time";
export const FullDateAndTime = "2018-01-19T18:45:00";
export const BasicDate = "Date";
export const FullDate = "2018-01-01";
export const BasicMonth = "Month";
export const FullMonth = "2018-01";
export const BasicWeek = "Week";
export const FullWeek = "BasicWeek";
export const BasicDatalistExample = "Datalist example";
export const ListPlaceholder = "Look up your nation...";
export const BasicTime = "Time";
export const BasicColorPicker = "Color picker";
export const BasicMaximumLength = "Maximum Length";
export const MaximumLengthPlaceholder = "Content must be in 6 characters";
export const ReadOnly = "Read only";
export const BasicStaticText = "Textarea";
export const StaticTextData = "Hello !... This is my magical text";
export const DefaultTextarea = "Default textarea";
export const BasicSubmitButton = "Submit";
export const BasicCancleButton = "Cancle";
export const BasicFloatingInputControls = "Basic floating input control";
export const FloatingValidInput = "Valid input ";
export const InputWithValue = "Input with value";
export const InvalidInput = "Invalid input";
export const FloatingComments = "Comments";
export const FloatingEmail = "Email";
export const FloatingPassword = "Password";
export const FloatingComment = "Comments";
export const FloatingCommentPlaceholder = "Leave a comment here";
export const FloatingCommentLabel = "Comments";
export const InputWithValuePlaceholder = "name@example.com";
export const InvalidInputPlaceholder = "name@example.com";
export const LayOutPlaceholder = "name@example.com";
export const LayOutDefaultvalue = "mdo@example.com";
export const CommentsPlaceholder = "Comments";
export const InputWithValuePlaceholderValue = "test@example.com";
export const InvalidInputPlaceholderValue = "test@example.com";
export const FloatingEmailLabel = "Email address";
export const FloatingOpenLabel = "Open this select menu";
export const FloatOpenFloatingLabel = "Works with selects";
export const FloatingInputGroup = "Input group";
export const InputGroup = "Input groups";
export const FloatingInputGroupLabel = "Username";
export const FloatingInputGroupFeed = "Please choose a username.";
export const FloatingLayout = "Layout";
export const FloatingCancleButton = "Cancle";
export const FloatingSubmittButton = "Submit";
export const EdgesInputStyles = "Edges input style";
export const ExampleSelect = "Example select";
export const EdgeEmailAddress = "Email address";
export const ReEnterPassword = "Re Enter Password";
export const EdgeEmailAddressPlaceholder = "name@example.com";
export const EdgePassword = "Password";
export const EdgePasswordPlaceholder = "Password";
export const SelectYourFavoritePixelstrapTheme = "Select your favorite pixelstrap Template";
export const DefaultFileInputExamples = "Default file input example";
export const MultipleFilesInputExample = "Multiple files input example";
export const DisabledFileInputExample = "Disabled file input example";
export const EdgeDefaultInput = "Default Input";
export const EdgeDefaultInputPlaceholder = "Default Input";
export const EdgeMale = "Male";
export const EdgeFemale = "Female";
export const EdgeComments = "Comments";
export const EdgeSubmit = "Submit";
export const EdgeCancel = "Cancel";
export const RaiseInputStyles = "Raise input style";
export const RaisedEmailAddress = "Email address";
export const RaisedEmailAddressPlaceholder = "name@example.com";
export const RaisedPassword = "Password";
export const RaisedPasswordPlaceholder = "Password";
export const RaisedSubmit = "Submit";
export const RaisedCancel = "Cancel";
export const RaisedSelectYourFavoritePixelstrapTheme = "Select your favorite pixelstrap Template";
export const SelectTheColorYouLikeBelow = "Select the color you like below";
export const RaisedComments = "Comments";

//Forms Validation
export const FormsControl = "Forms Control";
export const ValidationForms = "Validation Forms";
export const FormValidationTooltip = "Tooltip form validation";
export const LooksGood = "Looks good!";
export const UserName = "User Name";
export const UsernameFeedback = "Please choose a unique and valid username.";
export const Zip = "Zip";
export const StateChoose = "Choose...";
export const CityFeedback = "Please provide a valid city.";
export const StateFeedback = "Please select a valid state.";
export const ZipFeedback = "Please provide a valid zip.";
export const SubmitButton = "Submit form";
export const MarkFirstNamePlaceholder = "Mark";
export const OttolastNamePlaceholder = "Otto";
export const BrowserDefaults = "Browser defaults";
export const FirstNamePlaceholder = "First name";
export const PasswordBrowserDefault = "Password";
export const ChooseFile = "Choose file";
export const SelectYourPaymentMethod = "Select your payment method";
export const Visa = "Visa";
export const MasterCard = "MasterCard";
export const PayMethodTitle = "Select your payment method";
export const AgreeToThePolicies = "I agree to the policies";
export const SureInformation = "Are you sure above information are true";
export const ValidationsForms = "Validation form";
export const FirstNameError = "Please enter your valid firstname";
export const FirstNameFeedback = "Please enter your valid";
export const PasswordValidation = "Password";
export const PasswordFeedback = "Please enter your valid password";
export const StateInvalidFeedback = "Please select a valid state.";
export const CityInvalidFeedback = "Please provide a valid city..";
export const ZipInvalidFeedback = "Please provide a valid zip.";
export const VISA = "VISA";
export const SelectThemeTitle = "Select Your Favorite Pixelstrap Template";
export const ThemeError = "Invalid select feedback";
export const ChooseFiles = "Choose File";
export const InvalidFormFileSelected = "Invalid form file selected";
export const TextAreaPlaceHolder = "Enter your comment";
export const TextAreaError = "Please enter a message in the textarea.";
export const TermsError = "You must agree before submitting.";
export const AgreeCondition = "Agree to terms and conditions";
export const AgreeSubmitting = "You must agree before submitting.";

//Input groups
export const FormControls = "Form Controls";
export const ButtonAddon = "Button addons";
export const SubmitButtonAddon = "Submit";
export const SubmitButtonAddonPlaceHolder = "Recipient's username";
export const AddonPlaceHolder = "Recipient's username";
export const CustomForm = "Custom forms ";
export const OptionsFormGroup = "Options";
export const PixelstrapThemeTitle = "Select Your Favorite Pixelstrap Template";
export const FavoriteColorsTitle = "Select Your Favorite Colors";
export const FavoriteChocolatesTitle = "Select Your Favorite Chocolates";
export const FavoriteThemeTitle = "Select Your Favorite Template";
export const CustomFormSubmit = "Submit";
export const CustomFileInputs = "Custom file input";
export const CustomFileInputsUpload = "Upload";
export const CustomFileInputsVerify = "Verify";
export const CustomFileInputsSubmit = "Submit";
export const ButtonsWithDropdown = "Buttons With Dropdown";
export const ButtonsWithDropdownTitle = "Dropdown";
export const ToggleDropdown = "Toggle Dropdown";
export const SegmentedButton = "Segmented buttons";
export const SegmentedButtonInfo = "Info";
export const SegmentedButtonSecondary = "Secondary";
export const CheckboxesAndRadio = "Checkboxes and radios";
export const SmallInput = "Small";
export const DefaultInput = "Default";
export const LargeInput = "Large";
export const MultipleInput = "Multiple inputs";
export const FirstAndLastName = "First and last name";
export const BasicInputGroup = "Basic input groups";
export const BasicInputGroupBasic = "Basic";
export const YourVanityUrl = "Your vanity URL";
export const WithTextarea = "With textarea";
export const UsernamePlaceholder = "Username";
export const RecipientPlaceholder = "Recipient's username";
export const ServerPlaceholder = "Server";
export const Wrapping = "Wrapping";
export const ButtonBasicInputSubmit = "Submit";
export const ButtonBasicInputCancel = "Cancel";
export const VariationOfAddon = "Variation of addons";
export const LeftAddon = "Left Addon";
export const RightAddon = "Right Addon";
export const JointAddon = "Joint Addon";
export const LeftAddonPlaceholder = "Email";
export const RightAddonPlaceholder = "Recipient's username";
export const LeftAndRightAddon = "Left & Right Addon";
export const SolidStyle = "Solid style";
export const FlatStyle = "Flat style";
export const RaiseStyle = "Raise style";
export const LeftAndRight = "Left & Right Addon";
export const VariationOfAddonsSubmit = "Submit";
export const VariationOfAddonsCancel = "Cancel";

//Mega options
export const MegaOptions = "Mega Options";
export const VariationRadios = "Variation radio";
export const Submit = "Submit";
export const VariationCheckboxHeading = "Variation checkbox";
export const ChooseActivities = "Which of the following activities do you enjoy doing in your free time?";
export const UpgradeTitle = "It's time to";
export const UpgradeSpanText = "upgrade";
export const DefaultStyleMegaOptions = "Default style";
export const WithoutBordersStyles = "Without borders style";
export const SolidBorderStyles = "Solid border style";
export const OfferStyleBorders = "Offer style border";
export const InlineStyles = "Inline style";
export const VerticalStyles = "Vertical style";
export const HorizontalStyles = "Horizontal style";
export const City = "City";
export const State = "State";
export const Paypal = "Paypal";

//Datepicker
export const CalendarDefault = "Default calendar";
export const CalendarBootstrap = "Reactstrap calendar";
export const CustomizingConjunction = "Customizing Conjunction";
export const DefaultDates = "Default Date";
export const DisabledDates = "Disabled Dates";
export const HumanFriendly = "Human Friendly";
export const MinMaxValue = "Min-Max Value";
export const MultiplesDates = "Multiples Dates";
export const TimePickerTitle = "Time picker";
export const TimePickers = "Time Picker";
export const DatePicker = "Date Picker";
export const MultiplesDatesHeading = "Multiples Dates";
export const RangeDatePicker = "Range";
export const PreloadingDates = "Preloading Dates";
export const TimPickers = "Time picker";
export const TimePickerHeading = "Time Picker";
export const HourPicker = "24-hour Time Picker";
export const TimePickerLimits = "Time Picker W/Limits";
export const PreloadingTime = "Preloading Time";
export const TimePickerRange = "TimePicker with Limited Time Range";
export const TimePickerMinMaxRange = "TimePicker with Min/Max Time Range";
export const DateWithTime = "Date With Time";
export const DateAndTimeHeading = "Date and time";
export const Week = "Week";
export const Time = "Time";

//Touchspin
export const Touchspin = "Touchspin";
export const DefaultTouchspins = "Default touchspin";
export const OutlinedTouchspins = "Outlined touchspin";
export const IconsWithPrefixAndPostfixs = "Icons with prefix and postfix";
export const ButtonsWithPrefixAndPostfixs = "Buttons with prefix and postfix";
export const PreText = "Pre";
export const PostText = "Post";
export const RoundedTouchspin = "Rounded touchspin";

//Switch
export const FormsWidgets = "Forms Widgets";
export const Switch = "Switch";
export const CustomSwitchHeading = "Custom switch";
export const IconsSwitchHeading = "Icons switch";
export const UncheckedSwitchHeading = "Unchecked switch";
export const BordersWithIcon = "Borders with icons";
export const DisabledOutlineSwitchHeading = "Disabled outline switch";
export const VariationOfSwitchHeading = "Variation of switches";
export const SwitchSizingHeading = "Switch sizing";
export const SwitchWithIcon = "Switch with Icons";

//Typeahead
export const Typeahead = "Typeahead";
export const BasicTypeaheadHeading = "Basic typeahead";
export const StatesOfUSA = "States of USA";
export const PreFetchHeading = "Prefetch";
export const BloodHoundHeading = "Bloodhound (Suggestion Engine)";
export const RemotelyAheadHeading = "Remote typeahead";
export const CustomTemplate = "Custom templates";
export const MultipleSectionWithHeader = "Multiple sections with headers";
export const ScrollableDropDownMenuHeading = "Scrollable Dropdown Menu";

//Clipboard
export const Clipboard = "Clipboard";
export const ClipboardTextInput = "Clipboard on text input";
export const CutAndCopyText = "Cut/copy from text input";
export const CutAndCopyTextPlaceholder = "type some text to copy / cut";
export const Copy = "Copy";
export const Cut = "Cut";
export const ClipboardOnTextAreas = "Clipboard on textarea";
export const CutCopyFromTextarea = "Cut/copy from textarea";
export const ClipboardOnParagraphs = "Clipboard on paragraph";
export const CopyFromParagraph = "Copy from paragraph";
export const CopyPortionFromParagraphs = "Copy portion from paragraph";
export const ClipBoardCopy = "Copy Portion From Paragraph";
export const HighlightText = "Web design is the process of creating websites ";
export const CopyHighLightedText = " Copy highlighted text";

//From Layout - wizard 1
export const FormLayout = "Form Layout";
export const FormWizard = "Form Wizard";
export const NumberingWizardHeading = "Numbering wizard";
export const ConfirmPassword = "Confirm Password";
export const AgreeToTermsAndConditions = "Agree to terms and conditions";
export const PlaceHolderName = "Placeholder Name";
export const Expiration = "Expiration(MM/YY)";
export const CVVNumber = "CVV Number";
export const UploadDocumentation = "Upload Documentation";
export const AboveInformationCorrect = "All the above information is correct";
export const LinkedIn = "LinkedIn";
export const Github = "Git hub";
export const SelectState = "Select State";
export const GiveFeedback = "Give Feedback";
export const Congratulations = "Congratulations ";
export const StudentValidationFormHeading = "Student validation form";
export const Name = "Name";
export const AddProfile = "Add Profile";
export const PortfolioURL = " Portfolio URL";
export const ProjectDescription = " Project Description";
export const SocialLinks = " Social Links ";
export const Twitter = " Twitter";
export const VerticalValidationWizardHeading = "Vertical validation wizard";
export const SelectPaymentMethod = "Select your payment method";
export const NetBanking = " NET BANKING";
export const SelectYourBank = " SELECT YOUR BANK";
export const AgreeConditions = "Agree to terms and conditions";
export const Finish = "Finish";
export const ShippingFormHeading = "Shipping form";
export const BillingInformation = "Billing Information ";
export const FillFollowingInformation = "Fill up the following information ";
export const ShippingInformation = "Shipping Information";
export const SavedAddress = "Saved Address";
export const Information = "Information";
export const ShippingMethod = "Shipping Method";
export const ProceedNext = "Proceed to Next";
export const PaymentInformation = "Payment Information";
export const CreditCard = "Credit Card";
export const CashOnDeliveryHeading = "Cash On Delivery";
export const OrderConfirmed = "Thank you! Your order is confirmed.";
export const OrderID = "Order ID";
export const CurrentCartHeading = "Current Cart";
export const EnterYourName = "Enter your name";
export const EnterPassword = "Enter password";
export const EnterConfirmPassword = "Enter confirm password";
export const PlaceholderName = "Placeholder name";
export const ExpiryPlaceholder = "xx/xx";
export const LinkedInPlaceholder = "https://linkedIn.com/edmin";
export const GitHubPlaceholder = "https://github.com/edmin";
export const TwitterPlaceholder = "https://twitter.com/edmin";
export const AllTheAboveInformationIsCorrect = "All the above information is correct";
export const Address = "Address";
export const RememberMeForNextTime = "Remember me for next time";
export const OtherNotes = "Other notes";
export const EnterYourQueries = "Enter your queries...";
export const ProceedToNext = "Proceed to next";
export const EdminMail = "edmin@example.com";
export const AadharPlaceholder = "xxxx xxxx xxxx xxxx";
export const ZipCode = "Zip Code";
export const ContactNumber = "Contact Number";
export const EnterFirstName = "Enter first name";
export const EnterLastName = "Enter last name";
export const EnterNumber = "Enter number";
export const Country = "Country";
export const Product = "Product";
export const ProductDetails = "Product Details";
export const Price = "Price";
export const CardHolder = "Card Holder";
export const EnterCardHolderName = "Enter card holder name";
export const CVV = "CVV";
export const CardNumber = "Card Number";
export const EnterState = "Enter state";
export const Back = "Back";

//From Layout - wizard 2
export const StepFormWizard = "Step Form Wizard";
export const Continue = "Continue";
export const AadharNumber = "Aadhar Number";
export const PAN = "PAN";
export const ChooseBanks = "Choose from these popular banks";
export const DescribeYourIssue = "If no, could you please describe?";
export const SuccessfullyCompleted = "Successfully Completed";
export const AccountType = "Select the type of account";
export const AccountName = "Account Name";
export const SelectTeamWith = "Select team size with";
export const ContactDetails = "Contact details";
export const OrganizationName = "Organization Name";
export const JoinOrganizationType = "Join organization type";
export const OrganizationDescription = "Organization description";
export const CardHolderPlaceholder = "Enter card holder name";
export const PanPlaceholder = "xxxxxxxxxx";
export const InqMail = "org@support.com";
export const ShareYourProblemsAndAnotherIssues = "Share your problems and another issues";
export const CustomHorizontalWizardHeading = "Custom horizontal wizard";
export const BusinessVerticalWizardHeading = "Business vertical wizard";
export const BusinessHorizontalWizardHeading = "Business horizontal wizard";
export const CustomVerticalWizardHeading = "Custom vertical wizard";
export const GekkoCo = "Gekko Co";
export const Projects = "Projects";

//Two factor
export const TwoFactor = "Two Factor";
export const TwoFactorAuthenticationHeading = "Two-factor authentication";
export const FactorAuthentication = "2 factor authentication";
export const VerificationCodeHeading = "Verification code";
export const OtpCodeSent = "We've sent a verification code to";
export const OtpCode = "Your OTP Code here:";
export const Verify = "Verify";
export const Call = " Call";
export const ModalTitle = "Modal title";
export const ScanQRCode = "Scan QR code";
export const EmailVerificationHeading = "Email verification";
export const EnterQRCode = "Enter QR Code";
export const PleaseEnterTheCodeHere = "Please enter the code here";
export const Resend = "Resend";

//Reactstrap Table - Basic Table
export const ReactstrapTable = "Reactstrap Table";
export const ReactstrapBasicTable = "Reactstrap Basic Tables";
export const BasicTableWithBorderBottomColor = "Basic Table With Border Bottom Color";
export const InverseTables = "Inverse Table";
export const HoverableRowsWithHorizontalBorders = "Hoverable Rows With Horizontal Border";
export const InverseTablWithPrimaryBackgrounds = "Inverse Table with Primary background";
export const CaptionTableTitle = "Caption";
export const UserList = "List of users";
export const TableHeadOption = "Table head options";
export const StripedRowWithInverseTables = "Striped Row with Inverse Table";
export const BreckpointSpecifics = "Breckpoint Specific";
export const ResponsiveLightBackgroundTitle = "Responsive Tables With Light Background";
export const SizingTable = "Sizing Tables";
export const CustomColorHoverStrippedTitle = "Custom Table Color With Hover And Stripped";
export const DashedBorderTitle = "Dashed Border";

//Reactstrap Table - Table Components
export const TableComponent = "Table Component";
export const UIComponentsTitle = "UI Components";
export const AlertTitle = "Alerts";
export const ProgressbarTitle = "Progressbar";
export const CheckboxTitle = "Checkbox";
export const RadioButtonsTitle = "Radio Buttons";
export const SelectTable = "Select";
export const InputsTable = "Input";
export const BadgesTable = "Badges";
export const TooltipTriggersTitle = "Tooltip Triggers";
export const SwitchTable = "Switch";

//Data Tables - Basic Data Tables
export const BasicDataTables = "Basic DataTables";
export const DataTables = "Data Tables";
export const ZeroConfiguration = "Zero Configuration";
export const SearchTableButton = "Search";
export const StateSavingTitle = "State saving";
export const ScrollVerticalDynamicHeightTitle = "Scroll - vertical, dynamic height";

//Data Tables - Advance Init
export const AdvanceInit = "Advance Init";
export const StockResultTitle = `Stock Result`;
export const RowCreateCallBackSpan = "Row Create Callback";
export const APIDataTables = "API DataTables";
export const AddRowsTitle = "Add rows";
export const AddNewRowButton = "Add new row";
export const ChildrenRowsTitle = "Child rows (show extra / detailed information)";
export const ApiFullName = "Full Name: ";
export const ExtraInfo = "Extra info ";
export const ApiExtensionNumber = "Extension number: ";
export const ApiExtraInfo = "'And any further details here (images etc)...'";
export const RowsSelectionAndDeletions = "Row Selection And Deletion (Single Row)";
export const DeleteSelectDataButton = "Delete Row";
export const CustomFilteringSearch = "Custom filtering - range search";
export const MinimumAge = "Minimum age: ";
export const MaximumAge = "Maximum age:	";
export const DATASourceDataTables = "DATA Source DataTables";
export const HtmlTableTittle = "HTML (DOM) sourced data";
export const AjaxSourcedDataHeading = "Ajax sourced data";
export const JavascriptSourcedDataHeading = "Javascript sourced data";
export const ServerSideProcessingHeading = "Server-side processing";

//Miscellaneous - Gallery
export const Gallery = "Gallery";
export const ImageGallery = "Image Gallery";
export const MasonryGallery = "Masonry Gallery";
export const GalleryGridWithDescriptions = "Gallery Grid With Description";
export const GalleryGridWithDescriptionCap = "Image Gallery With Description";
export const MasonryGalleryWithDescription = "Masonry Gallery With Description";
export const MasonryGalleryCap = "Masonry Gallery";
export const MasonryGalleryDescriptionCap = "Masonry Gallery With Description";
export const PortfolioTitle = "Portfolio Title";
export const ImageHoverEffects = "Image Hover Effects";
export const MyPortfolioTitle = "My Portfolio";

//Blog
export const BlogDetails = "Blog Details";
export const Hits = "Hits";
export const SingleBlogHeading = `The Harpeth rises in the westernmost part of Rutherford County, just to the east of the community of College Grove in eastern Williamson County.`;
export const BlogSingle = `Blog Single`;
export const AddPost = "Add Post";
export const PostEdit = "Post Edit";
export const PostTitle = "Title";
export const PostTitlePlaceholder = "Post Title";
export const PostCategory = "Category";
export const PostType = "Type";
export const PostContent = "Content";
export const PostTypePlaceholder = "Select Your Name....";
export const BlogPostText = `Enter text in the area on the left. For more info, click the ? (help) icon in the menu.`;
export const BlogPostButton = "Post";
export const BlogDiscardButton = "Discard";
export const BlogDropFilesHereOrClickToUpload = "Drop files here or click to upload.";

//FAQ
export const Faq = "FAQ";
export const QuickQuestions = "Quick Questions are answered";
export const FeaturedTutorials = "Featured Tutorials";
export const LatestArticles = "Latest articles and videos";
export const SearchArticles = "Search Articles";
export const SearchArticlesPlaceholder = "Search..";
export const Navigation = "Navigation";
export const AskQuestion = "Ask Question";
export const AskOurCommunity = "Ask our community";
export const FaqContactUs = "Contact Us";
export const FaqLatestUpdates = "Latest Updates";

//Job Search
export const JobSearch = "Job Search";
export const CardsView = "Cards View";
export const JobApply = "Job Apply";
export const JobFilterData = "Job filter";
export const FilterHeading = "Filter";
export const FindJobs = "Find jobs";
export const LocationHeading = "Location";
export const AllLocations = "All Locations";
export const JobHeading = "Job Title";
export const AllJobTitle = "All Job Title";
export const IndustryHeading = "Industry";
export const AllIndustries = "All Industries";
export const ListView = "List View";
export const JobDetails = "Job Detail";
export const ApplyForThisJob = "Apply";
export const ProductDesignerMainCard = "Product Designer (UI/UX Designer)";
export const EndLessDesigner = "Endless - United States";
export const SimilarJobs = "Similar jobs";
export const JobDescriptionHeading = "Job Description";
export const Qualifications = "Qualifications";
export const AgencyExperience = "Agency experience";
export const Perks = "Perks";
export const Share = "share";
export const JobUIDesigner = "UI Designer";
export const JobEndlessTelecomTechnologie = "Endless Telecom & Technologies , NY";
export const JobPersonalDetails = "Personal Details";
export const JobYourEducation = "Your Education";
export const JobYourExperience = "Your Experience";
export const JobUploadYourFiles = "Upload Your Files";
export const JobSubmitButton = "Submit";
export const JobCancelButton = "Cancel";
export const FullNameJob = "Full name";
export const FullNameJobPlaceholder = "Enter your full name";
export const EmailJob = "Email";
export const EmailJobPlaceholder = "Enter email";
export const PasswordJob = "Password";
export const PasswordJobPlaceholder = "Enter password";
export const RepeatPasswordJob = "Repeat Password";
export const RepeatPasswordJobPlaceholder = "Repeat password";
export const PhoneNumberJob = "Phone Number";
export const PhoneNumberJobPlaceholder = "Enter Phone no.";
export const CollegeNameJob = "College Name";
export const PeriodJob = "Period";
export const DegreeLevelJob = "Degree Level";
export const SpecializationJob = "Specialization";
export const LocationJob = "Location";
export const PositionJob = "Position";
export const PositionJobPlaceholder = "Enter Position";
export const CompanyNameJob = "Company Name";
export const UploadCoverLetterJob = "Upload Cover Letter";
export const UploadYourCvJob = "Upload Your CV";
export const UploadRecommendationsJob = "Upload Recommendations";
export const BirthDateJob = "Birth Date";
export const CollagePlaceholder = "Enter college name";
export const DegreePlaceholder = "Degree";
export const SpecializationPlaceholder = "Enter specialization";
export const LocationJobPlaceholder = "Enter Location";
export const CompanyJobPlaceholder = "Enter Company Name";
export const SaveThisJob = " Save this job";
export const SpecificSkills = "Specific skills";
export const AllSkills = "All Skills";
export const LocationPlaceholder = "location..";
export const SearchPlaceholder = "search...";

//Learnings
export const LearningFilter = "Learning filter";
export const Learning = "Learning";
export const LearningList = "Learning List";
export const FindCourseLearning = "Find Course";
export const FilterLearning = "Filter";
export const SearchLearningPlaceholder = "Search..";
export const CategoriesLearning = "Categories";
export const CategoriesTitleLearning = "Categories";
export const DurationLearning = "Duration";
export const PriceLearning = "Price";
export const StatusLearning = "Status";
export const RegistrationLearning = "Registration";
export const DevelopmentTitle = "Development";
export const DesignLearning = "Design";
export const UpcomingCoursesHeading = "Upcoming Courses";
export const CourseBy = "Course By";
export const DetailedCourse = "Detailed Course";
export const CommentsLearning = "Comments";
export const DateLearning = "25 July 2024";
export const CommentTitleLearning = "Comment";

//Knowledgebase
export const Knowledgebase = "Knowledgebase";
export const KnowledgebaseHelpQue = "How Can I help you?";
export const KnowledgebasePlaceholder = "Type question here";
export const KnowledgeArticle = "Browse articles by category";
export const BrowseArticles = "Browse Articles";
export const FeaturedTutorial = "Featured Tutorials";

//Support Ticket
export const SupportTicket = "Support Ticket";
export const SupportTicketList = "Support Ticket List";
export const ProfitSupportTicket = "Profit";
export const LossSupportTicket = "Loss";
export const DeleteSupportTicket = "Delete";
export const DeleteData = "Delete Selected Data..!";

//Maps
export const GoogleMap = "Google Map";
export const BasicDemoMap = "Basic Demo";
export const CurrentLocation = "Current Location";
export const MarkerMap = "Marker Map";
export const PolygonsTitle = "POLYGONS";
export const Polygons = "Poly line Map";
export const LeafletMap = "Leaflet Map";
export const LeafletWorldMap = "Leaflet World Map";
export const LeafletUSAMap = "Leaflet USA Map";
export const LeafletIndiaMap = "Leaflet India Map";
export const LeafletAustraliaMap = "Leaflet Australia Map";
export const Maps = "Maps";

//Editors
export const Editors = "Editors";
export const AceEditor = "Ace Editor";
export const CkEditor = "Ck Editor";
export const CKEditorExample = "CK Editor";
export const EditorsText = "Your title";
export const InlineEditors = "Inline Editor";
export const MDEEditor = "MDE Editor";
export const MdeEditorExample = "MDE Editor Example";
export const SecondExample = "Second Example";
export const Instructions = "Instructions";
export const ACECodeEditor = "ACE Code Editor";
export const JavascriptModeTitle = "Javascript Mode";
export const HTMLModeHeading = "HTML Mode";
export const CSSModeHeading = "CSS Mode";
export const JavaModeHeading = "Java Mode";

// Page Layout
export const SampleCards = "Sample Card";
export const HideMenuOnScroll = "Hide Menu On Scroll";
export const PageLayout = "Page Layout";
export const SamplePage = "Sample Page";

// Error
export const BackToHomePage = "BACK TO HOME PAGE";
export const CreateYourAccount = "Create your account";
export const YourName = "Your Name";
export const PrivacyPolicy = "Privacy Policy";
export const AgreeWith = "Agree with";
export const SignUpWith = "Or signup with";
export const Unlock = "Unlock";
export const EnterYourPassword = "Enter your Password";
export const ResetYourPassword = "Reset Your Password";
export const EnterYourMobileNumber = "Enter Your Mobile Number";
export const SignUpToAccount = `Sign Up To Account`;
export const EmailPassWord = `Enter your email & password to login`;
export const HavePassport = "Have Passport";
export const Birthday = "Birthday";
export const RetypePassword = "Retype Password";
export const EnterOTP = "Enter OTP";
export const CreateYourPassword = "Reset Your Password";
export const NewPassword = "New Password";
export const MAINTENANCE = `MAINTENANCE`;
export const ContactNo = "Contact No";
export const Age = "Age";
export const Send = "Send";
export const Done = "Done";
export const EmailsPlaceHolder = "Test@gmail.com";
export const YesNo = "Yes/No";
export const ConfirmPasswordPlaceholder = "Enter again";
export const PageNotFound = "Page Not Found";

// Coming Soon
export const WeAreComingSoon = "WE ARE COMING SOON";

// Project
export const ProjectListHeading = "Project List";
export const CreateNewProjects = "Create New Project";
export const All = "All";
export const Doing = "Doing";
export const Issues = "Issues";
export const Resolved = "Resolved";
export const Comment = "Comment";
export const ProjectCreates = "Project Create";
export const Add = "Add";
export const ProjectTitle = "Project Title";
export const ClientName = "Client name";
export const ProjectRate = "Project Rate";
export const ProgressLevel = "Progress Level";
export const ProjectStatus = "Project Type";
export const ProjectSize = "Project Size";
export const Medium = "Medium";
export const Big = "Big";
export const StartingDate = "Starting date";
export const EnterSomeDetails = "Enter some Details";
export const UploadProjectFile = "Upload project file";
export const Apps = "Apps";
export const FileManagerHeading = "File Manager";
export const FileFilter = "File filter";
export const AddNew = "Add New";
export const AllFiles = "All Files";
export const Storage = "Storage";
export const PricingPlan = "Pricing Plan";
export const Selected = "Selected";
export const TrialVersion = "Trial Version";
export const FREE = "FREE";
export const Premium = "Premium";
export const QuickAccess = "Quick Access";
export const Folder = "Folders";
export const Upload = "Upload";
export const ClientPlaceholder = "Name client or company name";
export const ProjectNamePlaceholder = "Project name *";
export const Hourly = "Hourly";
export const FixPrice = "Fix price";
export const Priority = "Priority";
export const PriorityPlaceholder = "Select Status";
export const Low = "Low";
export const ProjectSizePlaceholder = "Select Issues";
export const High = "High";
export const Urgent = "Urgent";
export const ProjectProgress = "Project Rate";
export const ProjectProgressPlaceholder = "Enter project Progress";
export const EndingDate = "Ending date";
export const Small = "Small";
export const UploadProjectFiles = "Upload project file";
export const ActualFileUpload = " actually uploaded.)";
export const DropFileUploadText = "(This is just a demo dropzone. Selected files are ";
export const DropFilesHereOrClickToUpload = "Drop files here or click to upload.";

// SerachResult
export const SearchWebsite = `Search Website`;
export const SearchPages = `Search Pages`;
export const Images = "Images";
export const Videos = "Videos";
export const SearchPixelStrap = `Search result for "Pixelstrap"`;
export const Pixelstrap = "Pixelstrap ..";
export const FavouriteTVShows = "Favourite TV shows";
export const FavouriteMusicBands = "Favourite Music Bands ";
export const NewLikesThisWeek = "New Likes This Week";
export const MutualFriend = "Mutual Friends";
export const ActivityFeed = "Activity Feed";
export const Photo = "Photo";
export const MoreComments = "More Comments";
export const ProfileIntro = "Profile Intro";
export const SocialNetworks = "Social Networks";
export const Facebook = "Facebook";
export const Dribbble = "Dribbble";
export const AddFriend = "Add Friend";
export const LatestPhoto = "Latest Photos";
export const Friends = "Friends";
export const PepoleKnow = "Pepole You May Know";
export const HobbiesAndInterest = "Hobbies and Interests";
export const Hobbies = "Hobbies";
export const FavouriteMusicBandsArtists = "Favourite Music Bands / Artists";
export const FavouriteBooks = "Favourite Books";
export const FavouriteGames = "Favourite Games:";
export const FavouriteMovies = "Favourite Movies";
export const FavouriteWriters = "Favourite Writers:";
export const OtherInterests = "Other Interests:";
export const EducationAndEmploymentHeading = "Education and Employement";
export const TheNewCollegeOfDesign = "The New College of Design";
export const DigitalDesignIntern = "Digital Design Intern";
export const RembrandtInstitute = "Rembrandt Institute";
export const UXUIDesigner = "UI/UX Designer";
export const TheDigitalCollege = "The Digital College";
export const ViewedYourProfile = "Viewed Your Profile";
export const Tools = "Tools";
export const Audios = "Audios";
export const Search = "Search";

// Calender
export const CalenderHeading = "Calender Basic";
export const DraggableEvents = "Draggable Events";
export const RemoveAfterDrop = "remove after drop";
export const CalendarFilter = "calendar filter";

// Social App
export const SocialApp = "Social App";
export const MyPage = "My Page";
export const Likes = "Likes";
export const FavoriteTVShows = "Favorite TV shows";
export const FavoriteMusicBands = "Favorite Music Bands ";
export const PeopleKnow = "People You May Know";
export const PostYourComments = "Post Your comments";
export const ActivityLogHeading = "Activity Log";

// To-do
export const ToDoHeading = "To-Do";
export const ToDoFilter = "To Do filter";
export const ToDoList = "To Do List";
export const AddTask = "Add Task";
export const SocialAppHeading = "Social App";
export const AllTask = "All Task";
export const Completed = "Completed";
export const Pending = "Pending";
export const InProcess = "In Process";
export const TrashHeading = "Trash";
export const AddNewTaskHeading = "Add new task";
export const MarkAllAsFinishedHeading = "Mark all as finished";
export const MarkAllAsIncomplete = "Mark all as Incomplete";

// Tasks
export const TasksHeading = "Tasks";
export const TaskFilter = "task filter";
export const Views = "Views";
export const Holidays = "Holidays";
export const CreatedByMeHeading = "Created By Me";
export const TodayTasks = "Today's Tasks";
export const DelayedTasks = "Delayed Tasks";
export const UpcomingTasks = "Upcoming Tasks";
export const ThisWeekTask = "This week tasks";
export const AssignedToMe = "Assigned to me";
export const MyTasks = "My tasks";
export const ThisMonthTasks = "This month tasks";
export const NoTaskDueToday = "No task due today.";
export const NoTasksFound = "No tasks found.";
export const NewTask = "New Task";
export const AddNewTask = "Add New Task";
export const TaskTitle = "Task Title";
export const SubTask = "Sub task";
export const Print = "Print";
export const UserProfiles = "User Profile";
export const Designer = "Designer";
export const Germany = "Germany";
export const UserEdits = "User Edit";
export const UserCards = "User Cards";
export const Business = "Business";
export const CreateTagHeading = "Create Tag";
export const Newsletter = "News letter";
export const Notification = "Notification";
export const Tags = "Tags";
export const Collection = "Collection";
export const TagName = "Tag Name";
export const TagColor = "Tag Color";
export const RemindOn = "Remind on";
export const Mail = "Mail";

// Bookmark
export const BookmarkFilter = "Bookmark Filter";
export const Favourites = "Favourites";
export const SharedWithMe = "Shared with me";
export const AddBookmark = "Add Bookmark";
export const EditBookmark = "Edit Bookmark";
export const MyBookmark = " My bookmark";
export const Orgenization = "Organization";
export const NewBookmark = "New Bookmark";
export const WebUrl = "Web Url";
export const Group = "Group";
export const Title = "Title";
export const CreateTag = "Create Tag";
export const NoBookmarksFound = "No Bookmarks Found.";
export const CreatedByMe = "Created By Me";
export const Important = "Important";

// Email
export const EmailFilter = "email filter";
export const LetterBox = "Letter Box";
export const ComposeEmail = " Compose Email";
export const Sent = "Sent";
export const Starred = "Starred";
export const Draft = "Draft";
export const Trash = "Trash";
export const Work = "Work";
export const Private = "Private";
export const Support = "Support";
export const AddLabelHeading = "Add Label";
export const Promotion = "Promotion";
export const ComposeMessage = "Compose Message";
export const SaveAsDraft = "Save as draft";
export const InterviewMail = "Interview Mail";
export const LabelName = "Label Name";
export const LabelColor = "Label Color";
export const FromHeading = "From";
export const EnterYourMessages = "Enter Your Messages...";
export const Subject = "Subject";

// Contact
export const Contacts = "Contacts";
export const ContactFilter = "Contact Filter";
export const FollowUp = "Follow Up";
export const Ideas = "Ideas";
export const NewContacts = "New Contacts";
export const AddContacts = "Add Contacts";
export const Mobile = "Mobile";
export const Personal = "Personal";
export const History = "History";
export const MobileNo = "Mobile No";
export const PrintViews = "Print Views";
export const Organization = "Organization";
export const Gender = "Gender";
export const ContactHistory = "Contact History";
export const ContactCreated = "Contact Created";
export const Favorites = "Favorites";
export const EnterCategoryName = "Enter category name";
export const AddCategory = "Add Category";
export const Phone = "Phone";

// Payment Detail
export const PaymentDetails = "Payment Details";
export const NameOnCard = "Name on card";
export const CreditCards = "Credit Card";
export const DebitCards = "Debit Card";
export const FullNameHere = "Full name here";
export const CVC = "CVC";
export const Pincode = "Pincode";
export const EnterMobileNumber = "Enter mobile number";
export const AcHolderName = "AC Holder name";
export const AccountNumber = "Account number";
export const IcfcCode = "ICFC code";
export const OtherDetails = "Other Details";
export const Cod = "COD";
export const Emi = "EMI";
export const NetBankings = "Net Banking";
export const YourTotalBalance = "768,987.90";
export const ProductName = "Product Name";
export const ArticleNumber = "Article Number";
export const Units = "Units";
export const ExpirationDate = "Expiration Date";
export const AddProduct = "Add Product";
export const ProductList = "Product List";
export const ProductForm = "Product Form";
export const TypesOfProductDiscount = "Types of product discount";
export const CategoryName = "Category Name";
export const CreateNewCategoryHeading = "Create New Category";
export const ChooseYourCurrency = "Choose your currency";
export const InitialCost = "Initial cost";
export const SellingPrice = "Selling price";
export const ProductStocks = "Product stocks";
export const DragFilesHere = "Drag files here";
export const ProductTitle = "Product Title";
export const PublishDateTime = "Publish Date & Time";
export const PublishStatus = " Publish Status";
export const AddTag = "Add Tag";
export const LowStock = "Low Stock";
export const StockQuantity = "Stock Quantity";
export const SKU = "SKU";
export const RestockDate = "Restock Date";
export const PreOrder = "Pre-Order";
export const AllowBackOrders = "Allow Backorders";
export const StockAvailability = "Stock Availability";
export const WeightKg = "Weight (kg)";
export const Dimensions = "Dimensions";
export const ShippingClass = "Shipping Class";
export const Length = "Length[l]";
export const Width = "Width[w]";
export const Height = "Height[h]";
export const AdditionalTagTitle = "Additional Tag Title";
export const SpecificTags = "Specific Tags";
export const AdditionalDescription = "Additional Description";
export const EnterProductName = "Enter Product Name";
export const ProductDescription = "Product Description";
export const EnterProductDescription = "Enter Product Description";
export const ProductCategory = "Product Category";
export const BrandIcons = "Brand Icons";
export const Quality = "Quality";
export const ProductImageHeading = "Product Image";
export const SelectSizeHeading = "Select Size";
export const Size = "Size";

// Product
export const ProductPages = "Product Page";
export const ProductLists = "Product List";
export const ProductListHeading = "Individual Column Searching (Text Inputs)";
export const PaymentDetailsHeading = "Payment Details";
export const Creditcards = "Credit Card";
export const Debitcard = "Debit Card";
export const COD = "COD";
export const EMI = "EMI";
export const NetBankingTitle = "Net Banking";
export const Products = "Product";
export const Filters = "Filters";
export const AddToCartity = "Add to Cart";
export const Categorys = "Categorys";
export const AddToCartHeading = "Add to Cart";
export const CategoryHeading = "Category";
export const Colors = "Colors";
export const Apply = "Apply";
export const TotalPrice = "Total Price";
export const ContinueShopping = "Continue Shopping";
export const CartEmpty = "Cart Empty";
export const Checkouts = "Checkout";
export const BillingDetails = "Billing Details";
export const TownCity = "Town/City";
export const NewProducts = "New Products";
export const Quantity = "Quantity";
export const Carts = "Cart";
export const EnterCouponCode = "Enter coupon code";
export const StateCountry = "State/Country";
export const Shipping = "Shipping";
export const CheckPayments = "Check Payments";
export const CashOnDelivery = "Cash On Delivery";
export const PlaceOrder = "Place Order";
export const Subtotal = "Subtotal";
export const ProductPage = "Product Page";
export const WomenPinkshirt = "Women Pink shirt.";
export const ShareIt = "share it";
export const RateNow = "Rate Now";
export const AddToCart = "Add To Cart";
export const BuyNow = "Buy Now";
export const AddToWishList = "Add To WishList";
export const Brand = "Brand";

// OrderHistory
export const OrderHistory = "Orders History";
export const NewOrder = "New Orders";
export const ShippedOrders = "Shipped Orders";
export const CancelledOrders = "Cancelled Orders";
export const Datatableorderhistory = "Datatable order history";
export const Processing = "Processing";
export const Shipped = "Shipped";
export const ShippedOrder = "Shipped Orders";
export const CancelledOrder = "Cancelled Orders";
export const Cancelled = "Cancelled";
export const DataTableOrdersHistory = "Datatable Orders History";
export const MoveToCarts = "Move To Carts";

// Pricing
export const Pricing = "Pricing";
export const BecomeMembers = "Become member";
export const BecomeSignUpeMembers = "Sign Up";
export const Purchase = "Purchase";
export const SimplePricingCards = "Simple Pricing Card";

// Chat
export const Chat = "Chat";
export const PrivateChat = " Private Chat";
export const ChatsHeading = "Chats";
export const ContactsHeading = " Contacts";
export const RecentChats = "Recent chats";
export const NameAndPhoneNumber = "Name and phone number";
export const TypeMessageHere = "Type Message here..";
export const SendMessages = "Send messages";
export const AddToFavorites = "Add to favorites";
export const ViewDetails = "View Details";
export const GroupChat = "Group Chat";

// Invoices
export const BilledTo = "Billed To";
export const InvoiceDate = "Invoice Date";
export const InvoiceNumber = "Invoice Number";
export const AmountDus = "Amount Dus (USD)";
export const Rate = "Rate";
export const Qty = "Qty";
export const LineTotal = "Line Total";
export const AuthorizedSign = "Authorized Sign";
export const PrintInvoice = "Print Invoice";
export const Download = "Download";
export const InvoiceNo = "Invoice No.";
export const PaymentStatus = "Payment Status";
export const TotalAmount = "Total Amount";
export const BillingAddress = "BILLING ADDRESS";
export const ShippingAddress = "SHIPPING ADDRESS";
export const Unit = "Unit";
export const Vat = "Vat %";
export const ShippingRate = "ShippingRate";
export const Invoice = "Invoice";
export const InvoiceHeading = "INVOICE";
export const ClientDetails = "CLIENT DETAILS";
export const BankTransfer = "BANK TRANSFER";
export const BankAccount = "Bank Account";
export const AllTaxesIncluded = "All Taxes included";
export const InvoiceTo = "Invoice to";
export const AccountNo = "Account No";
export const DueAmount = "Due Amount";
export const UnitePrice = "Unite Price";
export const PaymentTeams = "Payment Teams";
export const Tax = "Tax";
export const Discount = "Discount";
export const TotalDue = "Total Due";
export const No = "No.";
export const VATTax = "VAT / Tax 15%";
export const Edmin = "Edmin";
export const ItemDescription = "Item Description";
export const SubTotal = "Sub-total";
export const InvoiceOne = "Invoice 1";
export const InvoiceTwo = "Invoice 2";
export const InvoiceThree = "Invoice 3";
export const InvoiceFour = "Invoice 4";
export const InvoiceFive = "Invoice 5";
export const InvoiceSix = "Invoice 6";
