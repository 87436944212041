import { Swiper, SwiperSlide } from "swiper/react";
import { Badges, H5, Image, P } from "../../../../AbstractElements";
import { carouselDataList } from "../../../../Data/BonusUi/Timeline/Timeline";
import { dynamicImage } from "../../../../Service";
import { CarouselText, DesignerTitle } from "../../../../utils/Constant";
import { Navigation, Pagination } from "swiper/modules";
import { useState } from "react";
import { Swiper as SwiperType } from "swiper";

const CarouselTimeline = () => {
  const CarouselSpanText = "Spend some time looking up current design trend.";
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-picture bg-success">
        <i className="icon-image" />
      </div>
      <div className="cd-timeline-content">
        <div className="timeline-wrapper">
          <Badges color="info">{DesignerTitle}</Badges>
        </div>
        <H5 className="f-w-500 m-0">{CarouselText}</H5>
        <P className="mb-0">{CarouselSpanText}</P>
        <div className="carousel slide">
          <Swiper navigation modules={[Navigation, Pagination]} pagination={{ clickable: true }} slidesPerView={1}  onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.realIndex)} className="carousel-inner">
            {carouselDataList.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                  <Image src={dynamicImage(item.image)} alt="drawing-room" className="d-block w-100" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <span className="cd-date">April 23 2024</span>
      </div>
    </div>
  );
};

export default CarouselTimeline;
