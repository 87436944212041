import { MapContainer, TileLayer } from "react-leaflet";
import { Card, CardBody, Col } from "reactstrap";
import CardHeaderCommon from "../../../../../CommonElements/CommonCardHeader/CardHeaderCommon";
import { WorldMapProps } from "../../../../../Data/Miscellaneous/Maps/Maps";
import { LeafletWorldMap } from "../../../../../utils/Constant";

const WorldMap = () => {
  return (
    <Col sm="6">
      <Card>
        <CardHeaderCommon title={LeafletWorldMap} borderClass={true} />
        <CardBody>
          <MapContainer style={{ height: 389 }} {...WorldMapProps} className="z-0 jvector-map-height">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </MapContainer>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WorldMap;
