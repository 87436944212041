import { Card, CardBody, Col } from "reactstrap";
import { LeafletUSAMap } from "../../../../../utils/Constant";
import { MapContainer, TileLayer } from "react-leaflet";
import CardHeaderCommon from "../../../../../CommonElements/CommonCardHeader/CardHeaderCommon";
import { UsaMapProps } from "../../../../../Data/Miscellaneous/Maps/Maps";

const USAMap = () => {
  return (
    <Col sm="6">
      <Card>
        <CardHeaderCommon title={LeafletUSAMap} borderClass={true} />
        <CardBody>
          <MapContainer className="jvector-map-height z-0" style={{ height: 389 }} {...UsaMapProps}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </MapContainer>
        </CardBody>
      </Card>
    </Col>
  );
};

export default USAMap;
