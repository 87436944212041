import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import axios from "axios";

interface Reward {
  id: number;
  user: number;
  company: number;
  company_name: string;
  branch: number;
  branch_name: string;
  transaction_type: string;
  credit: string;
  credit_date: string;
  points_available: string;
  points_pending: string;
}

interface RewardsState {
  rewards: Reward[];
  loading: boolean;
  error: string | null;
}

const initialState: RewardsState = {
  rewards: [],
  loading: false,
  error: null,
};

// **Async Thunk to Fetch Most Recent Rewards**
export const fetchMostRecentRewards = createAsyncThunk(
  "rewards/fetchMostRecentRewards",
  async (_, { getState, rejectWithValue }) => {

    const state = getState() as RootState;
    const accessToken = state.auth.access;

    try {
      const response = await axios.get("/api/rewards/my-recent/", {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data.most_recent_rewards_per_branch;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to fetch rewards");
    }
  }
);

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMostRecentRewards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMostRecentRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.rewards = action.payload;
      })
      .addCase(fetchMostRecentRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default rewardsSlice.reducer;
