import { Card, CardBody, Col } from "reactstrap";
import { DarkVariants } from "../../../../utils/Constant";
import { darkVariantData, darkVariantDataList } from "../../../../Data/BonusUi/Carousel/Carousel";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";
import { useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { H3, Image, P } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Swiper as SwiperType } from "swiper";

const DarkVariant = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Col xl="6" sm="12">
      <Card>
        <CardHeaderCommon title={DarkVariants} span={darkVariantData} headClass="pb-0" />
        <CardBody>
          <div className="carousel-dark">
            <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} loop slidesPerView={1} onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.realIndex)}>
              {darkVariantDataList?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                    <Image src={dynamicImage(slide.image)} alt="drawing-room" className="d-block w-100" />
                    <div className="carousel-caption d-none d-md-block carousel-opacity">
                      <H3>{slide.captionHeader}</H3>
                      <P>{slide.captionText}</P>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DarkVariant;
