import { Card, CardBody, Col } from "reactstrap";
import { WithControl } from "../../../../utils/Constant";
import { withControlData, withControlDataList, withControlSettingData } from "../../../../Data/BonusUi/Carousel/Carousel";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";

const WithControls = () => {
  return (
    <Col xl="6" xs="12">
      <Card>
        <CardHeaderCommon title={WithControl} span={withControlData} headClass="pb-0" />
        <CardBody>
          <Swiper {...withControlSettingData}>
            {withControlDataList.map((item, index) => (
              <SwiperSlide key={index}>
                <Image src={dynamicImage(item.image)} alt="drawing-room" className="d-block w-100" />
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WithControls;
