import CarouselContainer from "../../../Component/BonusUi/Carousel/Carousel"

const Carousel = () => {
  return (
    <div className='page-body'>
      <CarouselContainer />
    </div>
  )
}

export default Carousel