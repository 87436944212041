import { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollingModalHeading } from "../../../../utils/Constant";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import { Btn } from "../../../../AbstractElements";
import { 
  closeModal, 
  openModal, 
  selectDynamicComponent, 
  selectSelectedTask, 
  selectIsModalOpen, 
  loadDynamicComponent 
} from "../../../../ReduxToolkit/Reducers/TasksSlice";
import { log } from "console";

interface ExecuteTaskModalProps {
  taskTitle?: string;
  onExecute?: () => void;
}

const ExecuteTaskModal: React.FC<ExecuteTaskModalProps> = ({ taskTitle, onExecute }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsModalOpen);
  const selectedTask = useSelector(selectSelectedTask);
  const DynamicComponent = useSelector(selectDynamicComponent);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false); // Prevents reloading

  //console.log("🟢 ExecuteTaskModal - selectedTask:", selectedTask);
  

  // Extract stage from task description
  const extractStageFromDescription = (description: string): string | null => {
    const stageMatch = description?.match(/Stage:\s*(\w+)/);
    return stageMatch ? stageMatch[1] : "Start"; 
  };

  const stage = selectedTask?.description ? extractStageFromDescription(selectedTask.description) : "Start"; 
  //console.log("🟢 ExecuteTaskModal - stage:", stage);
  

  // Dynamically set modal title
  const modalTitle = selectedTask
    ? `Executing: ${selectedTask.title || "Task"}`
    : "Execute Task";

  // Load the dynamic component when modal opens (only once per task selection)
  useEffect(() => {
    if (isOpen && selectedTask && stage && !hasLoaded) {
      setIsComponentLoading(true);
      dispatch(loadDynamicComponent({ 
        appName: selectedTask.appName, 
        modelName: selectedTask.modelName, 
        stage
      }) as any).then(() => {
        setTimeout(() => { 
          setIsComponentLoading(false); 
          setHasLoaded(true); // Prevent re-fetching
        }, 500);
      });
    }
  }, [isOpen, selectedTask, stage, dispatch, hasLoaded]);

  const toggleModal = () => {
    if (isOpen) {
      dispatch(closeModal());
      setHasLoaded(false); // Reset on close
    } else {
      if (selectedTask) {
        dispatch(openModal(selectedTask));
      } else {
        console.error("❌ No selected task found!");
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-end mb-3">
      <Btn 
        color="success" 
        className="px-xl-2 px-xxl-3 px-xs-12 w-full md:w-auto block md:inline-block text-center"
        style={{ position: "relative", bottom: "0", zIndex: "10" }}
        onClick={onExecute ?? toggleModal}
      >
        Execute
</Btn>

      </div>

      <CommonModal isOpen={isOpen} toggle={toggleModal} title={modalTitle}>
        <Suspense fallback={<p>Loading task component...</p>}>
          {isComponentLoading ? (
            <p>Loading task component...</p>
          ) : DynamicComponent ? (
            <DynamicComponent selectedTask={selectedTask} />
          ) : (
            <p>Error loading component</p>
          )}
        </Suspense>
      </CommonModal>
    </>
  );
};

export default ExecuteTaskModal;
