import { useState, useEffect, Fragment } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import {
  getTasks,
  selectTasks,
  selectTaskStatus,
  openModal,
  closeModal,
  selectSelectedTask,
  selectIsModalOpen,
  selectProgress,
  updateProgress
} from "../../../../ReduxToolkit/Reducers/TasksSlice";
import moment from "moment";
import { H5, H6, Image, LI, UL, Progressbar } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import SvgIcon from "../../../../CommonElements/SVG/SvgIcon";
import ExecuteTaskModal from "./ExecuteTaskModal";

interface Task {
  id: number;
  title: string;
  due_date: string;
  assigned_users?: { avatar: string }[];
  branch?: { name: string };
  description?: string;
  appName: string;
  modelName: string;
  activity: string;
}

interface TaskListProps {
  selectedDate?: string;
}

const TaskList: React.FC<TaskListProps> = ({ selectedDate }) => {
  const dispatch = useAppDispatch();
  const tasks = useSelector(selectTasks);
  const taskStatus = useSelector(selectTaskStatus);
  const progress = useSelector(selectProgress);
  const isModalOpen = useSelector(selectIsModalOpen);
  const selectedTask = useSelector(selectSelectedTask);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);

  // Extract stage from task description
  const extractBatchNameFromDescription = (description: string): string | null => {
    const stageMatch = description?.match(/Batch:\s*(\w+)/);
    return stageMatch ? stageMatch[1] : "0";
  };

  // Extract stage from task description
  const extractStageFromDescription = (description: string): string | null => {
    const stageMatch = description?.match(/Stage:\s*(\w+)/);
    return stageMatch ? stageMatch[1] : "Start";
  };

  // Fetch tasks only when component mounts
  useEffect(() => {
    if (taskStatus === "idle") {
      dispatch(getTasks({ status: "active" }));
    }
  }, [dispatch, taskStatus]);

  // Handle Progress Bar Increment and Stop
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (taskStatus === "loading") {
      let progressValue = 0;
      dispatch(updateProgress(0));

      interval = setInterval(() => {
        if (progressValue < 90) {
          progressValue += 10;
          dispatch(updateProgress(progressValue));
        } else {
          clearInterval(interval!);
        }
      }, 500);
    }

    if (taskStatus === "succeeded") {
      dispatch(updateProgress(100));
      setTimeout(() => dispatch(updateProgress(0)), 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [taskStatus, dispatch]);

  // Filter tasks based on selected date
  useEffect(() => {
    if (selectedDate) {
      const filtered = tasks.filter((task: Task) =>
        moment.utc(task.due_date).local().format("YYYY-MM-DD") === selectedDate
      );
      setFilteredTasks(filtered);
    } else {
      setFilteredTasks(tasks);
    }
  }, [tasks, selectedDate]);

  return (
    <Row className="align-items-center">
      {progress > 0 && (
        <Col xs="12">
          <Card>
            <CardBody className="progress-showcase text-center">
              <H5 className="mb-2 f-w-500">Loading Your Tasks... {progress}%</H5>
              <Progressbar color="primary" striped animated value={progress} className="mb-4">
                {progress}%
              </Progressbar>
            </CardBody>
          </Card>
        </Col>
      )}

      {taskStatus === "succeeded" && filteredTasks.length > 0 ? (
        <Col xs="12" className="timeline-items" style={{ maxHeight: "290px", overflowY: "auto" }}>
          {filteredTasks.map((task, i) => (

            <Fragment key={i}>
              <div className={`timeline-box d-flex align-items-center b-w-39 b-t-primary`}>
                <div className="flex-grow-1">
                  <H6 className="f-15 f-w-600 mb-1">{task.title}</H6>
                  <div className="font-light">
                    <SvgIcon className="feather" iconId="clock" />
                    <span>{task.due_date ? `${moment(task.due_date).format("dddd, Do MMM")} (${moment(task.due_date).fromNow()})` : ""}</span>
                  </div>
                  {task.branch && (
                    <div className="font-light">
                      <SvgIcon className="feather" iconId="map-pin" />
                      <span className="font-light f-w-400 f-14">Batch: {task?.description ? extractBatchNameFromDescription(task.description) : "Start"}</span>
                    </div>
                  )}
                  <div className="font-light">
                    <SvgIcon className="feather" iconId="activity" />
                    <span className="font-light f-w-400 f-14">Activity: {task?.activity}</span>
                  </div>
                  <div className="font-light">
                    <SvgIcon className="feather" iconId="layers" />
                    <span className="font-light f-w-400 f-14">Stage: {task?.description ? extractStageFromDescription(task.description) : "Start"}</span>
                  </div>

                    {task.description && task.description.includes("Pond:") && task.description.match(/Pond:\s*(\w+)/)?.[1] && (
                    <div className="font-light">
                      <SvgIcon className="feather" iconId="droplet" />
                      <span className="font-light f-w-400 f-14">Pond: {task.description.match(/Pond:\s*(\w+)/)?.[1]}</span>
                    </div>
                    )}

                  {task.description && task.description.includes("Net:") && task.description.match(/Net:\s*(\w+)/)?.[1] && (
                    <div className="font-light">
                      <SvgIcon className="feather" iconId="droplet" />
                      <span className="font-light f-w-400 f-14">Pond: {task.description.match(/Net:\s*(\w+)/)?.[1]}</span>
                    </div>
                  )}

                </div>
                <div className="team-member text-end">
                  <div className="customers d-inline-block avatar-group">
                    <UL>
                      {task.assigned_users?.map((user, i) => (
                        <LI className="d-inline-block p-0" key={i}>
                          <Image className="img-38 b-r-8" src={dynamicImage(`user/${user.avatar}`)} alt="#" />
                        </LI>
                      ))}
                      <LI className="d-inline-block p-0">
                        <ExecuteTaskModal
                          onExecute={() => dispatch(openModal(task))}
                          taskTitle={task.title}
                        />
                      </LI>
                    </UL>
                  </div>
                </div>
              </div>
              <hr className="my-4" />
            </Fragment>
          ))}
        </Col>
      ) : taskStatus === "succeeded" && filteredTasks.length === 0 ? (
        <Col xs="12" className="text-center">
          <p>No active tasks available for : <b>{selectedDate ? moment(selectedDate).format("dddd, Do MMM") : ""}</b>.</p>
        </Col>
      ) : null}
    </Row>
  );
};

export default TaskList;
