import { H1, H2, H3, H4, H5, H6 } from "../../../../AbstractElements"

const ColorHeadingTableBody = () => {
  return (
    <tbody>
      <tr>
        <td><code>&lt;h1&gt;&lt;/h1&gt;</code></td>
        <td><H1><span className="font-primary"> Heading1 </span> SubHeading</H1></td>
      </tr>
      <tr>
        <td><code>&lt;h2&gt;&lt;/h2&gt;</code></td>
        <td><H2><span className="font-secondary"> Heading1 </span> SubHeading</H2></td>
      </tr>
      <tr>
        <td><code>&lt;h3&gt;&lt;/h3&gt;</code></td>
        <td><H3><span className="font-success"> Heading1 </span> SubHeading</H3></td>
      </tr>
      <tr>
        <td><code>&lt;h4&gt;&lt;/h4&gt;</code></td>
        <td><H4><span className="font-info"> Heading1 </span> SubHeading</H4></td>
      </tr>
      <tr>
        <td><code>&lt;h5&gt;&lt;/h5&gt;</code></td>
        <td><H5><span className="font-warning"> Heading1 </span> SubHeading</H5></td>
      </tr>
      <tr>
        <td className="pb-0"><code>&lt;h6&gt;&lt;/h6&gt;</code></td>
        <td className="pb-0"><H6><span className="font-danger"> Heading1 </span> SubHeading</H6></td>
      </tr>
    </tbody>
  )
}

export default ColorHeadingTableBody