import { Link } from "react-router-dom";
import { Badges, H5, LI, SVG } from "../../../../AbstractElements";
import { Href, Notifications, View } from "../../../../utils/Constant";
import UseOutsideDropdown from "../../../../utils/UseOutsideDropdown";
import ActivityUpdate from "./ActivityUpdate";

const NotificationHeader = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = UseOutsideDropdown(false);

  return (
    <LI className="custom-dropdown">
      <Link to={Href} ref={ref} onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <SVG className="svg-color circle-color" iconId="Bell" />
      </Link>
      <Badges pill color="secondary">
        3
      </Badges>
      <div className={`custom-menu notification-dropdown py-0 overflow-hidden ${isComponentVisible ? "show" : ""}`}>
        <H5 className="title bg-primary-light">
          {Notifications}
          <Link to={`${process.env.PUBLIC_URL}/chat/privatechats`}>
            <span className="font-primary">{View}</span>
          </Link>
        </H5>
        <ActivityUpdate />
      </div>
    </LI>
  );
};

export default NotificationHeader;
