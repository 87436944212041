import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { RootState } from "../Store";
import { backendURL } from "../../utils/Constant";
import { newTaskData } from "../../Data/Application/Tasks/Tasks";
import { TasksType } from "../../Type/Application/Tasks/Tasks";

// Define the state interface
interface TaskState extends TasksType {
  tasks: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  selectedTask: any | null;
  isModalOpen: boolean;
  progress: number;
  dynamicComponent: React.FC<any> | null;
}

// Initial state
const initialState: TaskState = {
  activeTab: "1",
  allTasks: newTaskData,
  validation: false,
  tasks: [],
  status: "idle",
  error: null,
  selectedTask: null,
  isModalOpen: false,
  progress: 0,
  dynamicComponent: null,
};

// Thunk to fetch tasks
export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async (
    params: {
      all?: boolean;
      company?: string;
      branch?: string;
      status?: string;
      manager?: boolean;
      owner?: boolean;
      assistant?: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const accessToken = state.auth.access;

    try {
      const response = await axios.get(`${backendURL}/api/company/tasks/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "An error occurred while fetching tasks."
      );
    }
  }
);

// Helper function for loading dynamic components
export const loadDynamicComponent = createAsyncThunk(
  "tasks/loadDynamicComponent",
  async ({ appName, modelName, stage }: { appName: string; modelName: string; stage: string }) => {
    try {
      const module = await import(`../../Component/Application/Tasks/${appName}/${modelName}/${stage}.tsx`);
      return module?.default || null;
    } catch (error) {
      console.error("Error loading component:", error);
      return null;
    }
  }
);

// Create the task slice
const TaskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setAllTasks: (state, action) => {
      state.allTasks = action.payload;
    },
    setValidation: (state, action) => {
      state.validation = action.payload;
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    clearSelectedTask: (state) => {
      state.selectedTask = null;
      state.isModalOpen = false;
      state.dynamicComponent = null;
    },
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.selectedTask = action.payload;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.selectedTask = null;
      state.dynamicComponent = null;
    },
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.progress = 0;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tasks = action.payload;
        state.progress = 100;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(loadDynamicComponent.fulfilled, (state, action) => {
        state.dynamicComponent = action.payload;
      });
  },
});

// Export reducers
export const {
  setActiveTab,
  setAllTasks,
  setValidation,
  selectTask,
  clearSelectedTask,
  openModal,
  closeModal,
  updateProgress,
} = TaskSlice.actions;

// Selectors
export const selectTasks = (state: RootState) => state.tasks.tasks;
export const selectTaskStatus = (state: RootState) => state.tasks.status;
export const selectTaskError = (state: RootState) => state.tasks.error;
export const selectSelectedTask = (state: RootState) => state.tasks.selectedTask;
export const selectIsModalOpen = (state: RootState) => state.tasks.isModalOpen;
export const selectProgress = (state: RootState) => state.tasks.progress;
export const selectDynamicComponent = (state: RootState) => state.tasks.dynamicComponent;

// Export reducer
export default TaskSlice.reducer;
