export const basicTimeLineData = [
  {
    text: "Use the ",
    code: ".main-basic-timeline ",
  },
  {
    text: "class through made basic timeline.",
  },
];

export const basicTimeLineDataList = [
  {
    color: "secondary",
    date: "22 March 2024",
    time: "3:45 PM",
    title: "DISCUSSION WITH MARKETING TEAM",
    span: "discussion with the marketing staff on the success of the most recent project",
  },
  {
    color: "success",
    date: "16 May 2024",
    time: "1:22 AM",
    title: "INVEST IN A NEW HOSTING PLAN",
    span: "today at 2 pm AM, purchase a new hosting package as agreed upon with the management team.",
  },
  {
    color: "warning",
    date: "23 Nov 2024",
    time: "6:56 AM",
    title: "DISCUSSION WITH DESIGNER TEAM",
    span: "discussion with the designer employee...",
  },
  {
    color: "info",
    date: "12 Dec 2024",
    time: "12:05 AM",
    title: "DISCUSSION WITH NEW TEMPLATE LAUNCH ",
    span: "discussion with the how many Template made in our portfolio.",
  },
  {
    color: "danger",
    date: "02 Jan 2024",
    time: "6:56 AM",
    title: "PURCHASE NEW CHAIRS FOR OFFICE ",
    span: "online purchase new chairs for office ",
  },
];

export const hoveringTimeLineData = [
  {
    text: "Use the ",
    code: ".square-timeline",
  },
  {
    text: " main class through create new variations of timeline.",
  },
];

export const variationTimeLineData = [
  {
    text: "Use the ",
    code: ".activity-dot-primary ",
  },
  {
    text: "through rounded animations.",
  },
];

export const meetupHoveringList = [
  {
    image: "avatar/1.jpg",
    name: "Lillian J. Goodfellow",
    number: "239-664-7751",
  },
  {
    image: "avatar/2.jpg",
    name: "Carolyn A. Sutton",
    number: "218-793-6609",
  },
];

export const variationTimeLineDataList = [
  {
    color: "warning",
    date: "5th Feb, 2024",
    time: "Today",
    title: "Added Bew Items",
    subTitle: "Quisque a consequat ante sit amet magna...",
    subTitleClass: "font-light",
    image: ["email-template/7.png", "email-template/8.png", "product/1.png"],
  },
  {
    className: "pb-0",
    color: "secondary",
    date: "20th Sep, 2022",
    time: "12:00 PM",
    title: "Tello just like your product",
    subTitle: "Quisque a consequat ante sit amet magna...",
  },
];

export const horizontalTimeLineData = [
  {
    text: "Use the ",
    code: "square-timeline",
  },
  {
    text: " main class through create new variations of timeline.",
  },
];

export const horizontalTimeLineDataList = [
  {
    mainClass: "timeline-border",
    child: [
      {
        colClass: "col-sm-6",
        color: "primary",
        date: "1 Jan",
        header: "Conference",
        paragraph: "Conferences are intended to bring individuals with similar interests together to discuss problems.",
        verticalLine1: "vertical-line",
      },
      {
        colClass: "col-sm-6",
        color: "success",
        date: "4 Feb",
        header: "Meet-up",
        paragraph: "You may connect with males from all around the world using the friend-forward.",
        verticalLine1: "vertical-line",
      },
      {
        colClass: "horizontal-timeline",
        color: "danger",
        date: "22 March",
        header: "Meeting",
        paragraph: "If several languages coalesce the grammar of the resulting simple and regular social networking platform.",
        verticalLine1: "vertical-line",
      },
    ],
  },
  {
    mainClass: "border-0",
    child: [
      {
        colClass: "col-sm-6",
        verticalLine2: "vertical-line",
        color: "primary",
        date: "1 Jan",
        header: "Conference",
        paragraph: "A gathering of two or more people to talk about issues of interest.",
      },
      {
        colClass: "col-sm-6",
        verticalLine2: "vertical-line",
        color: "danger",
        date: "22 March",
        header: "Meeting",
        paragraph: "If several languages coalesce the grammar of the resulting simple and regular social networking platform.",
      },
      {
        colClass: "horizontal-timeline",
        verticalLine2: "vertical-line",
        color: "warning",
        date: "7 Dec",
        header: "Launch Template",
        paragraph: "Responsive Website is the approach that suggests website design and development should respond.",
      },
    ],
  },
];

export const timeLineData = [
  {
    text: "This is a Edmin timeline chart.",
  },
];

export const carouselDataList = [
  {
    image: `banner/2.jpg`,
  },
  {
    image: `banner/1.jpg`,
  },
  {
    image: `banner/3.jpg`,
  },
];