import { Card, CardBody, Col } from "reactstrap";
import { fileUploadData } from "../../../../Data/BonusUi/Dropzone/Dropzone";
import { MultiFileUploads } from "../../../../utils/Constant";
import CommonFileUpload from "../../../Common/CommonFileUpload";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";

const MultiFileUpload = () => {
  return (
    <Col lg="6">
      <Card>
        <CardHeaderCommon title={MultiFileUploads} span={fileUploadData} />
        <CardBody>
          <CommonFileUpload multiple/>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MultiFileUpload;
