export const basicContainerStyle = {
  height: "500px",
};

export const basicCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const markerContainerStyle = {
  height: "500px",
};

export const markerCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const polygonContainerStyle = {
  height: "500px",
};

export const polygonCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const polylineContainerStyle = {
  height: "500px",
};

export const polylineCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const usaPosition = { lat: 50, lng: 10 };

export const WorldMapProps = {
  center: usaPosition,
  zoom: 2,
  maxZoom: 10,
  attributionControl: true,
  zoomControl: true,
  doubleClickZoom: true,
  scrollWheelZoom: true,
  dragging: true,
  easeLinearity: 0.35,
};

export const indianMapPosition = { lat: 20.5937, lng: 78.9629 };

export const IndiaMapProps = {
  center: indianMapPosition,
  zoom: 5,
};

export const australiaMapPosition = { lat: -25.2744, lng: 130.7751 };

export const AustraliaMapProps = {
  center: australiaMapPosition,
  zoom: 4,
  zoomControl: true,
  doubleClickZoom: true,
  scrollWheelZoom: true,
  dragging: true,
};

const UsaPosition = { lat: 37.0902, lng: -95.7129 };

export const UsaMapProps = {
  center: UsaPosition,
  zoom: 4,
  minZoom: 2,
  maxZoom: 10,
};
