import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { BonusUi, Tour } from "../../../utils/Constant";
import UserProfileFifthStyle from "./UserProfileFifthStyle/UserProfileFifthStyle";
import UserProfileFirstStyle from "./UserProfileFirstStyle/UserProfileFirstStyle";
import UserProfileFourthStyle from "./UserProfileFourthStyle/UserProfileFourthStyle";
import UserProfileSecondStyle from "./UserProfileSecondStyle/UserProfileSecondStyle";
import UserProfileThirdStyle from "./UserProfileThirdStyle/UserProfileThirdStyle";

const TourMain = () => {
  return (
    <>
      <Breadcrumbs mainTitle={Tour} parent={BonusUi} />
      <Container fluid className="user-profile">
        <Row>
          <UserProfileFirstStyle />
          <UserProfileSecondStyle />
          <UserProfileThirdStyle />
          <UserProfileFourthStyle />
          <UserProfileFifthStyle />
        </Row>
      </Container>
    </>
  );
};

export default TourMain;
