import { Card, CardBody, Col } from "reactstrap";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "../../../../AbstractElements";
import { withIndicatorData, withIndicatorDataList } from "../../../../Data/BonusUi/Carousel/Carousel";
import { dynamicImage } from "../../../../Service";
import { WithIndicator } from "../../../../utils/Constant";
import CardHeaderCommon from "../../../Ui-Kits/CardHeaderCommon";

const WithIndicators = () => {
  return (
    <Col xl="6" xs="12">
      <Card>
        <CardHeaderCommon title={WithIndicator} span={withIndicatorData} headClass="pb-0" />
        <CardBody>
          <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} loop>
            {withIndicatorDataList.map((item, index) => (
              <SwiperSlide key={index}>
                <Image src={dynamicImage(item.image)} alt="drawing-room" className="d-block w-100" />
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WithIndicators;
